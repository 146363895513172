import React from 'react';
import 'date-fns';
import Avatar from '@material-ui/core/Avatar';
import { Typography, Card, Container, Button } from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import {withStyles} from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { MixPanel } from '../components/MixPanel';
import { fetchCurrentThread, disableFetch, updateThreadTopic } from '../actions/threads';
import { color_hashtags_urls_mentions_in_threads, tweet_embedder } from '../config/helpers';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Icon } from '@iconify/react';
import { updateReadList, getReadList } from '../actions/user';
import repeatVariant from '@iconify-icons/mdi/repeat-variant';
import cardsHeart from '@iconify-icons/mdi/cards-heart';
import commentIcon from '@iconify-icons/mdi/comment';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {Helmet} from "react-helmet";
import { withLastLocation } from 'react-router-last-location';
import {withRouter} from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const styles = theme => ({
    app: {
      flexGrow: 1,
      // background: 'linear-gradient(-45deg, rgba(32,178,170,1) 0%, rgba(51,51,51,1) 30%, rgba(51,51,51,1) 50%, rgba(51,51,51,1) 80%, rgba(32,178,170,1) 100%)'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    chipDiv: {
        display: 'flex',
        flex: 0.8,
        justifyContent: 'center',
    },
    cardClass: {
        height: "100%",
        backgroundColor: theme.palette.paper.default,
        [theme.breakpoints.only('xs')]: {
            maxWidth: 300,
        },
        width: 820,
    },
    author: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    avatar: {
        marginRight: theme.spacing(1),
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    smallAvatar: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    name: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 320,
        marginLeft: theme.spacing(1),
        [theme.breakpoints.only('xs')]: {
            maxWidth: 140,
        },
    },
    date: {
        marginLeft: 'auto',
        color: theme.palette.primary.other
    },
    cardContent: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column',
        },
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.line.default,
    },
    authorLink: {
        textDecoration: 'none',
        opacity: 0.8,
        "&:hover": {
            opacity: 1
        }
    },
    authorname: {
        fontWeight: 'bold',
        opacity: 0.8,
        "&:hover": {
            opacity: 1
        }
    },
    buttonDiv: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    button: {
        opacity: 0.8,
        "&:hover": {
            opacity: 1,
            color: theme.palette.primary.main
        }
    },
    socialDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    iconLikeDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: theme.spacing(4),
        "&:hover": {
            "& $iconLike": {
                color: '#FE251B',
                opacity: 1
            },
            "& $likeDigit": {
                color: '#FE251B',
                opacity: 1
              }
        },
    },
    iconRetweetDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: theme.spacing(4),
        "&:hover": {
            "& $iconRetweet": {
                color: '#19cf86',
                opacity: 1
            },
            "& $retweetDigit": {
                color: '#19cf86',
                opacity: 1
              }
        },
    },
    iconReplyDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        "&:hover": {
            "& $iconReply": {
                color: '#00acee',
                opacity: 1
            },
            "& $replyDigit": {
                color: '#00acee',
                opacity: 1
              }
        },
    },
    iconLike: {
        opacity: 0.6
    },
    iconRetweet: {
        opacity: 0.6
    },
    iconReply: {
        opacity: 0.6
    },
    retweetDigit: {
        marginLeft: theme.spacing(1),
        opacity: 0.6
    },
    likeDigit: {
        marginLeft: theme.spacing(1),
        opacity: 0.6
    },
    replyDigit: {
        marginLeft: theme.spacing(1),
        opacity: 0.6
    },
    threadInfo: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column',
            width: '100%',
        },
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    threadInfoSub: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconStats: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    twitterIcon: {
        color: '#00acee'
    },
    title: {
        marginBottom: theme.spacing(4),
        color: 'white'
    },
    navContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    tweetBody: {
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: '0.5px dotted ' + theme.palette.line.lighter,
    },
    tweetBodyNoBorder: {
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    bottomContainer: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(8)
    },
    label: {
        marginRight: theme.spacing(2)
    },
    formControl: {
        marginRight: theme.spacing(3),
        minWidth: 120,
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    twitterCenter: {
          '& > div': {
            '& > .twitter-tweet': {
              marginLeft: 'auto',
              'marginRight': 'auto',
            }
          }
    },
    description: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        borderBottom: '1px solid ' + theme.palette.line.default,
    },
    engagement: {
        opacity: 0.6,
        "&:hover": {
            opacity: 1
        },
    },
    topicDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bodyLink: {
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'underline',
        }
    },
    iconSpacing: {
        marginRight: theme.spacing(0.6),
    },
});


class ThreadPage extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          loading: false,
          thread: {tweets: [], author: {_id: null}},
          topic: '',
          subTopic: '',
          timeRead: 0,
          markedRead: false,
      };
  }

  componentDidMount() {
      // start function
      this.setState({ loading: true })
      this.fetchThread().then(res => {
          MixPanel.track('View Thread Page', {
              'Thread ID': this.state.thread._id,
              'Thread Author': this.state.thread.author._id,
          })
          this.setState({
              loading: false,
              topic: 'topic' in this.state.thread ? this.state.thread.topic.topic: "",
              subtopic: 'subtopic' in this.state.thread ? this.state.thread.subtopic.subtopic: "",
          })
          const tweets = this.state.thread.tweets.length > 0 ? this.state.thread.tweets : this.props.thread.tweets;
          const wordCounts = tweets.map(elem => elem.text.split(' ').length);
          const numberDiv = wordCounts.reduce((a, b) => a + b, 0) / 200;
          const leftNum = Number(numberDiv.toString().split(".")[0]);
          const seconds = ('0.' + numberDiv.toString().split(".")[1]) * 0.60;
          const extraMin = seconds.toString().split(".")[1] >= 30 ? 1 : 0;
          this.setState({timeRead: leftNum + extraMin})
      });

  }
  componentDidUpdate(prevProps){
      if (this.props.thread !== prevProps.thread){
          const tweets = this.state.thread.tweets.length > 0 ? this.state.thread.tweets : this.props.thread.tweets;
          const wordCounts = tweets.map(elem => elem.text.split(' ').length);
          const numberDiv = wordCounts.reduce((a, b) => a + b, 0) / 200;
          const leftNum = Number(numberDiv.toString().split(".")[0]);
          const seconds = ('0.' + numberDiv.toString().split(".")[1]) * 0.60;
          const extraMin = seconds.toString().split(".")[1] >= 30 ? 1 : 0;
          this.setState({timeRead: leftNum + extraMin})
      }
  }

  fetchThread = async () => {
      if (this.props.location.thread) {
          this.setState({
              thread: this.props.location.thread,
              topic: 'topic' in this.props.location.thread ? this.props.location.thread.topic.topic : '',
              subTopic: 'subtopic' in this.props.location.thread ? this.props.location.thread.subtopic.subtopic : '',
              markedRead: '_id' in this.props.location.thread ? this.props.readThreads.includes(this.props.location.thread._id.split('_')[1]) : false
          })
      } else {
          this.props.getCurrentThread(this.props.match.params.id).then(res => {
              this.setState({
                  thread: this.props.thread,
                  topic: 'topic' in this.props.thread ? this.props.thread.topic.topic : '',
                  subTopic: 'subtopic' in this.props.thread ? this.props.thread.subtopic.subtopic : '',
                  markedRead: '_id' in this.props.thread ? this.props.readThreads.includes(this.props.thread._id.split('_')[1]) : false
              })
          })
      }
  }

  handleTopicChange = (event) => {
      this.setState({topic: event.target.value})
      let topic_id;
      if (event.target.value === ''){
          topic_id = ''
      } else {
          const topic = this.props.initialTopics.filter(topic => topic.topic === event.target.value)[0];
          topic_id = topic._id
      }
      this.props.updateTopic(this.state.thread._id.split('_')[1], topic_id, '')
  }

  handleSubtopicChange = (event) => {
      this.setState({subTopic: event.target.value})
      let topic_id = '';
      let subtopic_id = '';
      const topic = this.state.topic === '' ? [] : this.props.initialTopics.filter(item => item.topic === this.state.topic)
      if (topic.length > 0){
          const subtopic = topic[0].subtopics.filter(topic => topic.subtopic === event.target.value)[0];
          topic_id = topic[0]._id
          subtopic_id = subtopic._id
      }
      this.props.updateTopic(this.state.thread._id.split('_')[1], topic_id, subtopic_id)
  }

  handleClickSeeOnTwitter = (e, elem) => {
      e.stopPropagation();
      MixPanel.track('See On Twitter', {'Tweet ID': elem.tweet_id})
      window.open('https://twitter.com/bipdash/status/' + elem.tweet_id)
  }

  handleClickThreadDesigner = (e, elems) => {
      e.stopPropagation();
      const ecrypted = window.btoa(elems.map(elt => elt.tweet_id).join(','));
      window.open("https://storychief.io/utilities/twitter-threads/"+ecrypted);
  }

  handleReadChange = (event, threadId, userId) => {
    this.setState({markedRead: !this.state.markedRead})
    this.props.updateReadList(threadId, userId).then(res => {
        MixPanel.track('Mark Thread As Read', {'Thread ID': threadId})
        this.props.getReadList(userId).then(res =>{
            console.log(this.props.readThreads)
        })
    })
  };

  render () {
    const { classes, theme } = this.props;
    let tweet = this.state.thread.tweets[0] ? this.state.thread.tweets[0] : { text: '', _id: ''}
    let description = tweet.text.split(' ').slice(0, 10).join(' ') + '...';
    let topic = this.state.thread.topic ? this.state.thread.topic.topic : '';
    let subtopic = this.state.thread.subtopic ? this.state.thread.subtopic.subtopic: '';
    let username = this.state.thread.author ? this.state.thread.author.username : '';
    let userId = this.props.user.twitter_id;
    let tweetId = this.state.thread.tweets[0] ? this.state.thread.tweets[0]._id.split('_')[1] : '';
    return(

        <div className={classes.app}>
          <Helmet>
            <title>{"Bipdash - thread on " + topic + ' > ' + subtopic + " by " + username}</title>
            <meta name="title" content={"Bipdash - thread on " + topic + ' > ' + subtopic + " by " + username}/>
            <meta name="description" content={description} />
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={"https://bipdash.com/thread/" + tweetId}/>
            <meta property="og:title" content={"Bipdash - thread on " + topic + ' > ' + subtopic + " by " + username}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content="https://mugshotbot.com/m/gNz0dW34"/>
            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content={"https://bipdash.com/thread/" + tweetId}/>
            <meta property="twitter:title" content={"Bipdash - thread on " + topic + ' > ' + subtopic + " by " + username}/>
            <meta property="twitter:description" content={description}/>
            <meta property="twitter:image" content="https://mugshotbot.com/m/gNz0dW34"/>
          </Helmet>
            <Container maxWidth='md' className={classes.navContainer}>
                {this.props.isLoggedIn && <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.markedRead}
                            onChange={(e) => this.handleReadChange(e, tweetId, userId)}
                            color="primary"
                        />
                    }
                    label="Mark as Read"
                />}
                {((this.props.isLoggedIn && this.props.user.twitter_id === '438323244') || process.env.REACT_APP_USER_ADMIN === '1') && <div className={classes.chipDiv}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="topic-label" className={classes.label}>Topic</InputLabel>
                        <Select
                          labelId="topic-label"
                          id="topic-select"
                          value={this.state.topic}
                          onChange={this.handleTopicChange}
                          style={{width: 300, marginRight: 20}}
                        >
                            <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                            {this.props.initialTopics.map(topic => (
                                <MenuItem key={topic._id} value={topic.topic}>{topic.topic}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="subtopic-label" className={classes.label}>Subtopic</InputLabel>
                        <Select
                          labelId="subtopic-label"
                          id="subtopic-select"
                          value={this.state.subTopic}
                          onChange={this.handleSubtopicChange}
                          style={{width: 300}}
                        >
                            <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                            {this.state.topic &&
                                this.props.initialTopics.map(t => t.topic).includes(this.state.topic) &&
                                this.props.initialTopics.filter(item => item.topic === this.state.topic)[0].subtopics.map(topic => (
                                <MenuItem key={topic._id} value={topic.subtopic}>{topic.subtopic}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>}
            </Container>
          <Container className={classes.container}>

            {this.state.thread.tweets.length > 0 && <Card className={"MuiThreadPage--01"}>
                <a target="_blank" rel="noopener noreferrer" href={"https://twitter.com/" + this.state.thread.author.username} className={classes.authorLink}>
                    <div className={classes.author}>
                         <Avatar
                           alt={this.state.thread.author.name.toUpperCase()}
                           src={this.state.thread.author.profile_image_url.replace('_normal', '_bigger')}
                           aria-label="open account menu"
                           className={classes.avatar}
                       />
                       <div className={classes.name}>
                           <Typography className={classes.authorname} noWrap>
                               {this.state.thread.author.name}
                           </Typography>
                           <Typography variant='caption' className={classes.username}>
                               {'@' + this.state.thread.author.username}
                           </Typography>
                       </div>
                   </div>
                   </a>
                   <Typography className={classes.description}>
                       {this.state.thread.author.description}
                   </Typography>

                <CardActions className={classes.cardActions}>
                    <div className={classes.socialDiv}>
                        <div className={classes.iconStats}>
                            <div className={classes.iconReplyDiv}>
                                <Icon icon={commentIcon} className={classes.iconReply} width="1.1em"/>
                                <Typography variant='subtitle2' className={classes.replyDigit}>{this.state.thread.tweets[0].reply_count}</Typography>
                            </div>
                            <div className={classes.iconRetweetDiv}>
                                <Icon icon={repeatVariant} className={classes.iconRetweet} width="1.5em" />
                                <Typography variant='subtitle2' className={classes.retweetDigit}>{this.state.thread.tweets[0].retweet_count}</Typography>
                            </div>
                            <div className={classes.iconLikeDiv}>
                                <Icon icon={cardsHeart} className={classes.iconLike} width="1.2em"/>
                                <Typography variant='subtitle2' className={classes.likeDigit}>{this.state.thread.tweets[0].like_count}</Typography>
                            </div>
                        </div>
                        <Typography className={classes.engagement}>
                            Engagement score: <b>{Math.round(this.state.thread.engagement_score * 1000) / 1000}</b>
                        </Typography>
                    </div>

                    <div className={classes.threadInfo}>
                        <div className={classes.threadInfoSub}>
                            <Typography variant='subtitle2' className={classes.date}>
                                Published {moment(this.state.thread.tweets[0].created_at).fromNow()}
                            </Typography>
                            <Typography variant='subtitle2' className={classes.timeRead}>
                                <i><b>{this.state.thread.tweets.length}</b>{' tweets - '}<b>{this.state.timeRead}</b>{' min read' }</i>
                            </Typography>
                        </div>
                        <div className={classes.threadInfoSub}>
                            <Button className={classes.button} onClick={(e) => this.handleClickSeeOnTwitter(e, this.state.thread.tweets[0])}>
                               See on Twitter
                            </Button>
                            {/* <Button className={classes.button} onClick={(e) => this.handleClickThreadDesigner(e, this.state.thread.tweets)}>
                               <svg
                                   stroke="currentColor"
                                   fill="currentColor"
                                   strokeWidth="0"
                                   viewBox="0 0 512 512"
                                   height="1em"
                                   width="1em"
                                   xmlns="http://www.w3.org/2000/svg"
                                   className={classes.iconSpacing}
                                >
                                <path d="M224 96l16-32 32-16-32-16-16-32-16 32-32 16 32 16 16 32zM80 160l26.66-53.33L160 80l-53.34-26.67L80 0 53.34 53.33 0 80l53.34 26.67L80 160zm352 128l-26.66 53.33L352 368l53.34 26.67L432 448l26.66-53.33L512 368l-53.34-26.67L432 288zm70.62-193.77L417.77 9.38C411.53 3.12 403.34 0 395.15 0c-8.19 0-16.38 3.12-22.63 9.38L9.38 372.52c-12.5 12.5-12.5 32.76 0 45.25l84.85 84.85c6.25 6.25 14.44 9.37 22.62 9.37 8.19 0 16.38-3.12 22.63-9.37l363.14-363.15c12.5-12.48 12.5-32.75 0-45.24zM359.45 203.46l-50.91-50.91 86.6-86.6 50.91 50.91-86.6 86.6z">
                                </path>
                            </svg>Thread Designer
                            </Button> */}
                        </div>
                    </div>
                </CardActions>

                <CardContent className={classes.cardContent}>
                    {'topic' in this.state.thread && 'active' in this.state.thread.topic && this.state.thread.topic.active && <div className={classes.topicDiv}>
                        <Chip
                          label={'subtopic' in this.state.thread ? this.state.thread.topic.topic + ' > ' + this.state.thread.subtopic.subtopic
                                                                       : this.state.thread.topic.topic}
                          style={{
                              backgroundColor: this.state.thread.topic.backgroundColor,
                              margin: theme.spacing(1),
                              color: this.state.thread.topic.color,
                          }}
                        />
                    </div>}
               {this.state.thread.tweets.map((elem, index) => (

                           <div key={elem._id} className={index === this.state.thread.tweets.length -1 ? classes.tweetBodyNoBorder : classes.tweetBody}>
                           {color_hashtags_urls_mentions_in_threads(elem.text).map(e => (
                             e.substring(1,9) === 'https://' ?
                               <a target="_blank" rel="noopener noreferrer" key={uuidv4()} href={e.substring(1)} className={classes.bodyLink}>
                                       <Typography variant='body2' component='div' display="inline" key={uuidv4()} style={{whiteSpace: 'pre-wrap', color: theme.palette.primary.other}}>
                                       {tweet_embedder(
                                           elem.urls.filter(elt => elt.url === e.substring(1))[0] ? elem.urls.filter(elt => elt.url === e.substring(1))[0]: elem.urls[0],
                                           this.props.isDark ? "dark" : "light",
                                           classes
                                       )}
                                   </Typography>
                               </a>
                                :
                           e[0] === '%' ? e[1] === '#' ? <Typography variant='body2' display="inline" key={uuidv4()} style={{whiteSpace: 'pre-wrap', color: theme.palette.primary.other}}>
                                   {e.substring(1)}
                               </Typography>
                               : <a target="_blank" rel="noopener noreferrer" key={uuidv4()} href={'https://twitter.com/' + e.substring(2)} className={classes.bodyLink}>
                                   <Typography variant='body2' display="inline" key={uuidv4()} style={{whiteSpace: 'pre-wrap', color: theme.palette.primary.other}}>
                                           {e.substring(1)}
                                       </Typography>
                                   </a>

                           :<Typography variant='body2' display="inline" key={uuidv4()} style={{whiteSpace: 'pre-wrap', textDecoration: 'none'}}>
                               {e}
                           </Typography>)
                           )}
                           </div>
                         )
                 )}
                 </CardContent>
            </Card>}
        </Container>
        </div>
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCurrentThread: (id) => dispatch(fetchCurrentThread(id)),
    disableFetch: () => dispatch(disableFetch()),
    updateTopic: (id, topic, subtopic) => dispatch(updateThreadTopic(id, topic, subtopic)),
    updateReadList: (threadId, userId) => dispatch(updateReadList(threadId, userId)),
    getReadList: (userId) => dispatch(getReadList(userId))
  };
}

function mapStateToProps(state, props) {
  return {
    thread: state.threads.current,
    user: state.auth.user? state.auth.user: {twitter_id: ''},
    isLoggedIn: state.auth.isLoggedIn,
    initialTopics: state.threads.initialTopics ? state.threads.initialTopics : [],
    isDark: state.mode.isDark,
    readThreads: state.user.readThreads ? state.user.readThreads : [],
  };
}

export default withLastLocation(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ThreadPage))))
