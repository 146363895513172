import {
    UPDATE_DATA_STARTED,
    UPDATE_DATA_FAILED,
    UPDATE_DATA_SUCCESS,
    SET_TAPPED_NODE,
    SET_INSTRUCTION_TEXT,
    RESET_DATA,
    SET_LOADING
} from '../constants/actionTypes';

const initialState = {
  error: null,
  tappedNodeData: null,
  data: [],
}

const graphReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DATA_STARTED:
      return {
        ...state,
        error: null,
    }
    case UPDATE_DATA_FAILED:
      return {
           ...state,
          error: action.payload.error,
          tappedNodeData: null,
    }
    case UPDATE_DATA_SUCCESS:
      return {
         ...state,
        data: action.payload.data,
        error: null
    }

    case SET_TAPPED_NODE:
      return {
         ...state,
        tappedNodeData: action.payload,
    }

    case SET_LOADING:
      return {
         ...state,
        loading: action.payload,
    }

    case SET_INSTRUCTION_TEXT:
      return {
         ...state,
        instructionText: action.payload === 'loading' ? 'Retrieving data. Please wait...'
                       : action.payload === 'done' ? 'Click on any node to find related entities. Double click to open website or Twitter url in new tab.'
                       : action.payload === 'impossible' ? 'Sorry, cannot expand because there are too many relationships!'
                       : 'Node already expanded.',
    }

    case RESET_DATA:
      return {
         ...state,
        data: [],
    }

    default:
      return state
  }
}

export default graphReducer
