import {
    FETCH_SETTINGS_STARTED,
    FETCH_SETTINGS_SUCCESS,
    FETCH_SETTINGS_FAILED,
    UPDATE_SETTINGS_STARTED,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAILED,
} from '../constants/actionTypes'
import { saveSettingsApi, fetchSettingsApi } from '../api/settings';

export function fetchSettings(userId) {
  return function (dispatch) {
    dispatch(fetchSettingsStarted())
    return fetchSettingsApi(userId)
      .then((response) => {
        if ('errors' in response) {
            console.log({ response })
            dispatch(fetchSettingsFailed(response.errors))
        }
        else {
            let user = response[0];
            let topics = user.topics.map(t => t.topic.topic)
            topics = [...new Set(topics)];
            const subtopics0 = user.topics.filter(t => t.topic.topic === topics[0]).map(t => t.subtopic);
            const subtopics1 = user.topics.filter(t => t.topic.topic === topics[1]).map(t => t.subtopic);
            dispatch(fetchSettingsSuccess({
                email: user.email,
                userId: userId,
                topics: {[topics[0]]: subtopics0, [topics[1]]: subtopics1},
            }))
        }
      })
      .catch(err => {
          dispatch(fetchSettingsFailed(err.message))
      });
  }
}

const fetchSettingsSuccess = data => ({
  type: FETCH_SETTINGS_SUCCESS,
  payload: data
});

const fetchSettingsStarted = () => ({
  type: FETCH_SETTINGS_STARTED
});

const fetchSettingsFailed = error => ({
  type: FETCH_SETTINGS_FAILED,
  payload: {
    error
  }
});

export function saveSettings(userId, topics, email) {
  return function (dispatch) {
    dispatch(saveSettingsStarted())
    return saveSettingsApi(userId, topics, email)
      .then((response) => {
        if ('errors' in response) {
            dispatch(saveSettingsFailed(response.errors))
        }
        else {
            dispatch(saveSettingsSuccess(response))
        }
      })
      .catch(err => {
          dispatch(saveSettingsFailed(err.message))
      });
  }
}

const saveSettingsSuccess = data => ({
  type: UPDATE_SETTINGS_SUCCESS,
  payload: {
      data
  }
});

const saveSettingsStarted = () => ({
  type: UPDATE_SETTINGS_STARTED
});

const saveSettingsFailed = error => ({
  type: UPDATE_SETTINGS_FAILED,
  payload: {
    error
  }
});
