import React from 'react';
import 'date-fns';
import Avatar from '@material-ui/core/Avatar';
import { Typography, Card, Container } from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import {withStyles} from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { MixPanel } from '../components/MixPanel';
import { getReadList } from '../actions/user';
import { withLastLocation } from 'react-router-last-location';
import {withRouter} from 'react-router-dom';
import CTACard from './CTACard';
import photoMe from "../assets/me.png";


const styles = theme => ({
    app: {
      flexGrow: 1,
      // background: 'linear-gradient(-45deg, rgba(32,178,170,1) 0%, rgba(51,51,51,1) 30%, rgba(51,51,51,1) 50%, rgba(51,51,51,1) 80%, rgba(32,178,170,1) 100%)'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    chipDiv: {
        display: 'flex',
        flex: 0.8,
        justifyContent: 'center',
    },
    cardClass: {
        height: "100%",
        backgroundColor: theme.palette.paper.default,
        [theme.breakpoints.only('xs')]: {
            maxWidth: 300,
        },
        width: 820,
    },
    author: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    avatar: {
        marginRight: theme.spacing(1),
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    smallAvatar: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    name: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 320,
        marginLeft: theme.spacing(1),
        [theme.breakpoints.only('xs')]: {
            maxWidth: 140,
        },
    },
    date: {
        marginLeft: 'auto',
        color: theme.palette.primary.other
    },
    cardContent: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column',
        },
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.line.default,
    },
    authorLink: {
        textDecoration: 'none',
        opacity: 0.8,
        "&:hover": {
            opacity: 1
        }
    },
    authorname: {
        fontWeight: 'bold',
        opacity: 0.8,
        "&:hover": {
            opacity: 1
        }
    },
    buttonDiv: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    button: {
        opacity: 0.8,
        "&:hover": {
            opacity: 1,
            color: theme.palette.primary.main
        }
    },
    socialDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    iconLikeDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: theme.spacing(4),
        "&:hover": {
            "& $iconLike": {
                color: '#FE251B',
                opacity: 1
            },
            "& $likeDigit": {
                color: '#FE251B',
                opacity: 1
              }
        },
    },
    iconRetweetDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: theme.spacing(4),
        "&:hover": {
            "& $iconRetweet": {
                color: '#19cf86',
                opacity: 1
            },
            "& $retweetDigit": {
                color: '#19cf86',
                opacity: 1
              }
        },
    },
    iconReplyDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        "&:hover": {
            "& $iconReply": {
                color: '#00acee',
                opacity: 1
            },
            "& $replyDigit": {
                color: '#00acee',
                opacity: 1
              }
        },
    },
    iconLike: {
        opacity: 0.6
    },
    iconRetweet: {
        opacity: 0.6
    },
    iconReply: {
        opacity: 0.6
    },
    retweetDigit: {
        marginLeft: theme.spacing(1),
        opacity: 0.6
    },
    likeDigit: {
        marginLeft: theme.spacing(1),
        opacity: 0.6
    },
    replyDigit: {
        marginLeft: theme.spacing(1),
        opacity: 0.6
    },
    threadInfo: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column',
            width: '100%',
        },
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    threadInfoSub: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconStats: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    twitterIcon: {
        color: '#00acee'
    },
    title: {
        marginBottom: theme.spacing(4),
        color: 'white'
    },
    navContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    tweetBody: {
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: '0.5px dotted ' + theme.palette.line.lighter,
    },
    tweetBodyNoBorder: {
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    bottomContainer: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(8)
    },
    label: {
        marginRight: theme.spacing(2)
    },
    formControl: {
        marginRight: theme.spacing(3),
        minWidth: 120,
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    twitterCenter: {
          '& > div': {
            '& > .twitter-tweet': {
              marginLeft: 'auto',
              'marginRight': 'auto',
            }
          }
    },
    description: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        borderBottom: '1px solid ' + theme.palette.line.default,
    },
    engagement: {
        opacity: 0.6,
        "&:hover": {
            opacity: 1
        },
    },
    topicDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bodyLink: {
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'underline',
        }
    },
});


class About extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          timeRead: 2,
          author: {
              username: '_JonathanBarone',
              name: 'Jonathan Barone',
              profile_image_url: 'https://pbs.twimg.com/profile_images/1411500160228622336/UG2z_CeI_bigger.jpg',
          }
      };
  }

  componentDidMount() {
      // start function
      MixPanel.track('View About Page')
  }

  render () {
    const { classes } = this.props;
    return(

        <div className={classes.app}>
          <Container className={classes.container}>

            <Card className={"MuiThreadPage--01"}>
                <a target="_blank" rel="noopener noreferrer" href={"https://twitter.com/" + this.state.author.username} className={classes.authorLink}>
                    <div className={classes.author}>
                         <Avatar
                           alt={this.state.author.name.toUpperCase()}
                           src={photoMe}
                           aria-label="open account menu"
                           className={classes.avatar}
                       />
                       <div className={classes.name}>
                           <Typography className={classes.authorname} noWrap>
                               {this.state.author.name}
                           </Typography>
                           <Typography variant='caption' className={classes.username}>
                               {'@' + this.state.author.username}
                           </Typography>
                       </div>
                   </div>
                   </a>

                <CardContent className={classes.cardContent}>

                           <div className={classes.tweetBodyNoBorder}>

                           <Typography variant='body2' display="inline" style={{whiteSpace: 'pre-wrap', textDecoration: 'none'}}>
                               Hey <span role="img" aria-label="emoji waving">👋</span><br/>
                               <br/>
                               I'm Jonathan, a solo builder building Bipdash on the side.
                               <br/>
                               Thank you for your curiosity and your willingness to know more about the tool <span role="img" aria-label="emoji handcheck">🤝</span>
                               <br/><br/>
                               Bipdash is solving 2 main issues with how I use Twitter (yea I kinda scratched my own itch at first, and then shaped with feedback from other builders/creators):
                               <br/>
                               - What content is important and what kind of content is it?<br/>
                               - How can I quickly lookup a thread? How can I search for particular types of threads 🧵?<br/>
                               <br/>
                               I started my journey on Twitter following a lot of indie makers and creators and quickly noticed how difficult it can be to simply not miss valuable content. This holds even more true for the build in public movement where many accounts tweet their updates, challenges, launches, announcements, lessons learned etc...
                               <br/>
                               It quickly became overwhelming to review what had happened that day in my Twitter bubble.<br/>
                               <br/>
                               Bipdash is the product attempting to solve these problems for people from the creator/maker economy.
                               <br/>
                               Today you can browse threads, read them as if they were blog posts, and subscribe to the email digest to receive a weekly email of threads organized per topics you care about.
                               <br/><br/>
                               In the future I see Bipdash giving even more information like stats on threads. What are the threads to read depending on where you are in your journey as a maker/creator. Realtime notifications for certain types of threads. Learning paths bundles of threads etc...<br/>
                               <br/>
                               While I hope Bipdash helps you getting the value you are looking for, I'm always available for a chat (DMs open <span role="img" aria-label="emoji sunglasses">😎</span>) whether you have feedback or simply to connect with you!
                           </Typography>
                           </div>
                 </CardContent>
            </Card>
            {!(this.props.isLoggedIn && this.props.user.topics.length > 0) && <CTACard />}
        </Container>
        </div>
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getReadList: (userId) => dispatch(getReadList(userId))
  };
}

function mapStateToProps(state, props) {
  return {
    user: state.auth.user? state.auth.user: {twitter_id: '', topics: []},
    isLoggedIn: state.auth.isLoggedIn,
  };
}

export default withLastLocation(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(About))))
