import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react'

ReactDOM.render(

     <Provider store={store}>
         <PersistGate loading={null} persistor={persistor}>
             <Router>
                 <LastLocationProvider>
                     <Route path="/" component={App} />
                 </LastLocationProvider>
             </Router>
         </PersistGate>
      </Provider>,
  document.querySelector('#root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
