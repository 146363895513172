import { elUrl } from '../config/urls';
import btoa from 'btoa';

export const searchApi = async (value) => {
    return fetch(elUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa(process.env.REACT_APP_ELK_API_USER+':'+process.env.REACT_APP_ELK_API_PASSWORD)
        },
        body: JSON.stringify({
            query: {
              match: {
                name: value
              }
            },
            sort: ['_score']
        }),
    }).then(response => response.json())
}
