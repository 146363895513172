import {
    UPDATE_USER_LIST_THREADS_READ_STARTED,
    UPDATE_USER_LIST_THREADS_READ_FAILED,
    UPDATE_USER_LIST_THREADS_READ_SUCCESS,
    GET_USER_LIST_THREADS_READ_STARTED,
    GET_USER_LIST_THREADS_READ_FAILED,
    GET_USER_LIST_THREADS_READ_SUCCESS,
} from '../constants/actionTypes'
import { updateReadListApi, getReadListApi } from '../api/user';


export function updateReadList(threadId, userId) {
  return function (dispatch) {
    dispatch(updateReadListStarted())
    return updateReadListApi(threadId, userId)
      .then((data) => {
              dispatch(updateReadListSuccess(data))
      })
      .catch(err => {
          dispatch(updateReadListFailed(err.message))
      });
  }
}

const updateReadListSuccess = data => ({
  type: UPDATE_USER_LIST_THREADS_READ_SUCCESS,
  payload: {
      data
  }
});

const updateReadListStarted = () => ({
  type: UPDATE_USER_LIST_THREADS_READ_STARTED
});

const updateReadListFailed = error => ({
  type: UPDATE_USER_LIST_THREADS_READ_FAILED,
  payload: {
    error
  }
});


export function getReadList(userId) {
  return function (dispatch) {
    dispatch(getReadListStarted())
    return getReadListApi(userId)
      .then((data) => {
              dispatch(getReadListSuccess(data[0].readThreads))
      })
      .catch(err => {
          dispatch(getReadListFailed(err.message))
      });
  }
}

const getReadListSuccess = data => ({
  type: GET_USER_LIST_THREADS_READ_SUCCESS,
  payload: data
});

const getReadListStarted = () => ({
  type: GET_USER_LIST_THREADS_READ_STARTED
});

const getReadListFailed = error => ({
  type: GET_USER_LIST_THREADS_READ_FAILED,
  payload: {
    error
  }
});
