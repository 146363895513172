import {
    EL_SEARCH_FAILED,
    EL_SEARCH_STARTED,
    EL_SEARCH_SUCCESS,
    SET_SEARCH_VALUE,
    RESET_OPTIONS,
} from '../constants/actionTypes';

const initialState = {
  loading: false,
  searchValue: { name: '', id: '' },
  error: null,
  options: []
}

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case EL_SEARCH_STARTED:
      return {
        ...state,
        error: null,
        loading: true
    }
    case EL_SEARCH_FAILED:
      return {
           ...state,
          error: action.payload.error,
          loading: false,
    }
    case EL_SEARCH_SUCCESS:
      return {
         ...state,
        options: action.payload.data,
        loading: false,
    }

    case SET_SEARCH_VALUE:
      return {
         ...state,
        searchValue: action.payload,
    }

    case RESET_OPTIONS:
      return {
         ...state,
        options: [],
    }

    default:
      return state
  }
}

export default searchReducer
