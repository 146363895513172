import {
    EL_SEARCH_STARTED,
    EL_SEARCH_SUCCESS,
    EL_SEARCH_FAILED,
    SET_SEARCH_VALUE,
    RESET_OPTIONS,
} from '../constants/actionTypes'
import { searchApi } from '../api/search';


export function searchEl(searchTerm) {
  return function (dispatch) {
    dispatch(searchStarted())
    return searchApi(searchTerm)
      .then((data) => {
          if (!('hits' in data)){
              dispatch(searchFailed('Something went wrong with the search'))
          } else {
              const results = data.hits.hits.map(h => h._source)
              dispatch(searchSuccess(results))
          }
      })
      .catch(err => {
          dispatch(searchFailed(err.message))
      });
  }
}

const searchSuccess = data => ({
  type: EL_SEARCH_SUCCESS,
  payload: {
      data
  }
});

const searchStarted = () => ({
  type: EL_SEARCH_STARTED
});

const searchFailed = error => ({
  type: EL_SEARCH_FAILED,
  payload: {
    error
  }
});

export const setSearchValue = value => ({
  type: SET_SEARCH_VALUE,
  payload: value
});

export const resetOptions = () => ({
  type: RESET_OPTIONS
});
