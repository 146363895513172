import { apiBaseUrl } from '../config/urls';

export const saveSettingsApi = async (userId, topics, email) => {
    return fetch(apiBaseUrl + '/api/user/' + userId, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"topics": topics, "email": email})
    })
        .then(res => {
            return res.json();
        })
        .catch(err => {
            return err
        })
}

export const fetchSettingsApi = async (userId) => {
    return fetch(apiBaseUrl + '/api/user/' + userId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
    })
        .then(res => {
            return res.json();
        })
        .catch(err => {
            return err
        })
}
