import {
    FETCH_SETTINGS_STARTED,
    FETCH_SETTINGS_SUCCESS,
    FETCH_SETTINGS_FAILED,
    UPDATE_SETTINGS_STARTED,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAILED,
} from '../constants/actionTypes';

const initialState = {
  error: null,
  loading: false,
  email: '',
  userId: '',
  topics: {topic1: [], topic2: []},
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
      case FETCH_SETTINGS_STARTED:
        return {
          ...state,
          error: null,
          loading: true
      }
      case FETCH_SETTINGS_FAILED:
        return {
             ...state,
            error: action.payload.error,
            loading: false
      }
      case FETCH_SETTINGS_SUCCESS:
        return {
           ...state,
          data: action.payload.data,
          email: action.payload.email,
          userId: action.payload.userId,
          topics: action.payload.topics,
          error: null,
          loading: false
      }
    case UPDATE_SETTINGS_STARTED:
      return {
        ...state,
        error: null,
        loading: true
    }
    case UPDATE_SETTINGS_FAILED:
      return {
           ...state,
          error: action.payload.error,
          loading: false
    }
    case UPDATE_SETTINGS_SUCCESS:
      return {
         ...state,
        error: null,
        loading: false
    }
    default:
      return state
  }
}

export default settingsReducer
