// auth
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const SET_SESSION_STARTED = 'SET_SESSION_STARTED';
export const SET_SESSION_SUCCESS = 'SET_SESSION_SUCCESS';
export const SET_SESSION_FAILED = 'SET_SESSION_FAILED';
// subscribe
export const SUBSCRIBE_FAILED = 'SUBSCRIBE_FAILED';
export const SUBSCRIBE_STARTED = 'SUBSCRIBE_STARTED';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_GET_LIST = 'SUBSCRIBE_GET_LIST';
export const SUBSCRIBE_GET_LISTS = 'SUBSCRIBE_GET_LISTS';
// graph
export const UPDATE_DATA_STARTED = 'UPDATE_DATA_STARTED';
export const UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS';
export const UPDATE_DATA_FAILED = 'UPDATE_DATA_FAILED';
export const RESET_DATA = 'RESET_DATA';
export const SET_INSTRUCTION_TEXT = 'SET_INSTRUCTION_TEXT';
export const SET_TAPPED_NODE = 'SET_TAPPED_NODE';
export const SET_LOADING = 'SET_LOADING';
// search
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const EL_SEARCH_STARTED = 'EL_SEARCH_STARTED';
export const EL_SEARCH_SUCCESS = 'EL_SEARCH_SUCCESS';
export const EL_SEARCH_FAILED = 'EL_SEARCH_FAILED';
export const RESET_OPTIONS = 'RESET_OPTIONS';
// threads
export const FETCH_THREADS_STARTED = 'FETCH_THREADS_STARTED';
export const FETCH_THREADS_SUCCESS = 'FETCH_THREADS_SUCCESS';
export const FETCH_THREADS_FAILED = 'FETCH_THREADS_FAILED';
export const FETCH_THREAD_USERS_STARTED = 'FETCH_THREAD_USERS_STARTED';
export const FETCH_THREAD_USERS_SUCCESS = 'FETCH_THREAD_USERS_SUCCESS';
export const FETCH_THREAD_USERS_FAILED = 'FETCH_THREAD_USERS_FAILED';
export const FETCH_THREAD_TOPICS_STARTED = 'FETCH_THREAD_TOPICS_STARTED';
export const FETCH_THREAD_TOPICS_SUCCESS = 'FETCH_THREAD_TOPICS_SUCCESS';
export const FETCH_THREAD_TOPICS_FAILED = 'FETCH_THREAD_TOPICS_FAILED';
export const FETCH_CURRENT_THREAD_STARTED = 'FETCH_CURRENT_THREAD_STARTED';
export const FETCH_CURRENT_THREAD_SUCCESS = 'FETCH_CURRENT_THREAD_SUCCESS';
export const FETCH_CURRENT_THREAD_FAILED = 'FETCH_CURRENT_THREAD_FAILED';
export const UPDATE_THREAD_TOPIC_STARTED = 'UPDATE_THREAD_TOPIC_STARTED';
export const UPDATE_THREAD_TOPIC_SUCCESS = 'UPDATE_THREAD_TOPIC_SUCCESS';
export const UPDATE_THREAD_TOPIC_FAILED = 'UPDATE_THREAD_TOPIC_FAILED';
export const NO_REFRESH = 'NO_REFRESH';
export const REFRESH = 'REFRESH';
export const SET_FILTERS = 'SET_FILTERS';
// mode
export const SET_MODE = 'SET_MODE';
// settings
export const FETCH_SETTINGS_STARTED = 'FETCH_SETTINGS_STARTED';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILED = 'FETCH_SETTINGS_FAILED';
export const UPDATE_SETTINGS_STARTED = 'UPDATE_SETTINGS_STARTED';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILED = 'UPDATE_SETTINGS_FAILED';
// user
export const UPDATE_USER_LIST_THREADS_READ_STARTED = 'UPDATE_USER_LIST_THREADS_READ_STARTED';
export const UPDATE_USER_LIST_THREADS_READ_FAILED = 'UPDATE_USER_LIST_THREADS_READ_FAILED';
export const UPDATE_USER_LIST_THREADS_READ_SUCCESS = 'UPDATE_USER_LIST_THREADS_READ_SUCCESS';
export const GET_USER_LIST_THREADS_READ_STARTED = 'GET_USER_LIST_THREADS_READ_STARTED';
export const GET_USER_LIST_THREADS_READ_FAILED = 'GET_USER_LIST_THREADS_READ_FAILED';
export const GET_USER_LIST_THREADS_READ_SUCCESS = 'GET_USER_LIST_THREADS_READ_SUCCESS';
