import React from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import { fetchThreadTopics} from '../actions/threads';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { apiBaseUrl } from '../config/urls';


const styles = theme => ({
    app: {
      width: '100%'
      // background: 'linear-gradient(-45deg, rgba(32,178,170,1) 0%, rgba(51,51,51,1) 30%, rgba(51,51,51,1) 50%, rgba(51,51,51,1) 80%, rgba(32,178,170,1) 100%)'
    },
    subscribeFields: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    subscribeText: {
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(2),
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(20),
    },
    button: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary.darker,
        color: 'white',
        "&:hover": {
            backgroundColor: theme.palette.primary.main
        },
    },
});

class CTACard extends React.Component {

  handleSignIn = () => {
      window.open(apiBaseUrl + "/auth/twitter", "_self");
  }

  goToSettings = () => {
    this.props.history.push(`/settings`);
  };

  render() {
      const { classes } = this.props;
      return (
              <div className={classes.app}>
                  <Container maxWidth='md'>
                      <Card className={"MuiSubscribeCard--01"}>
                          <div className={classes.subscribeFields}>
                          <Typography align='center' variant="h4" className={classes.subscribeText} gutterBottom>
                              Setup your email digest <span role="img" aria-label="emoji thread">🧵</span>
                          </Typography>
                          <Typography align='center' variant="subtitle1">
                              It's a weekly email containing the 3 best threads for each of 2 topics of your choice
                          </Typography>
                          {this.props.isLoggedIn ? <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.button}
                            onClick={this.goToSettings}
                          >
                            Set My Email Settings
                          </Button>
                         : <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.button}
                            onClick={(e) => this.handleSignIn(e)}
                          >
                            Sign In with Twitter
                        </Button>}
                          </div>
                    </Card>
                  </Container>
              </div>
      );
  }
}

function mapDispatchToProps(dispatch) {
  return {
      fetchTopics: () => dispatch(fetchThreadTopics()),
  };
}

function mapStateToProps(state, props) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(CTACard)));
