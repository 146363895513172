import {
    SUBSCRIBE_FAILED,
    SUBSCRIBE_STARTED,
    SUBSCRIBE_SUCCESS,
    SUBSCRIBE_GET_LISTS
} from '../constants/actionTypes';

const initialState = {
  isLoading: false,
  error: null,
  emailResult: null,
  subscribers: 27,
  openedlistId: ''
}

const subscribeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIBE_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
        emailResult: null
    }
    case SUBSCRIBE_FAILED:
      return {
           ...state,
          error: action.payload.error,
          isLoading: false,
          emailResult: null
    }
    case SUBSCRIBE_SUCCESS:
      return {
         ...state,
        isLoading: false,
        error: null,
        emailResult: action.payload
    }
    case SUBSCRIBE_GET_LISTS:
      return {
         ...state,
        subscribers: action.payload.counts.subscribed,
        openedlistId: action.payload.id,
    }

    default:
      return state
  }
}

export default subscribeReducer
