import {
    CLEAR_SESSION,
    SET_SESSION_STARTED,
    SET_SESSION_SUCCESS,
    SET_SESSION_FAILED,
} from '../constants/actionTypes'
import { loginApi } from '../api/auth';
import { setSearchValue } from './search'
import { MixPanel } from '../components/MixPanel';

export function clearSession() {
    return function (dispatch) {
        MixPanel.reset();
        return dispatch(doClearSession())
    }
}

export const doClearSession = () => ({
  type: CLEAR_SESSION
})

export function loginUser() {
  return function (dispatch) {
    dispatch(setSessionStarted())
    return loginApi()
      .then((responseJson) => {
          if (!('user' in responseJson)) {
              dispatch(setSessionFailed(responseJson.error))
          } else {
            MixPanel.identify(responseJson.user.twitter_id);
            MixPanel.people.set({
              $name: responseJson.user.screen_name,
              $email: responseJson.user.email,
            });
            MixPanel.track('Sign In', {Username: responseJson.user.screen_name});
            const searchValue = {
                name: responseJson.user.name,
                type: 'User',
                id: "https://bipdash.com/user_" + responseJson.user.twitter_id,
                profileImageUrl: responseJson.user.profile_image_url.replace('_normal', '_bigger')
            }
            dispatch(setSearchValue(searchValue))
            dispatch(setSessionSuccess(responseJson.user))
          }
      })
      .catch(err => {
          dispatch(setSessionFailed(err.message))
      });
  }
}

const setSessionSuccess = user => ({
  type: SET_SESSION_SUCCESS,
  payload: {
      user
  }
});

const setSessionStarted = () => ({
  type: SET_SESSION_STARTED
});

const setSessionFailed = error => ({
  type: SET_SESSION_FAILED,
  payload: {
    error
  }
});
