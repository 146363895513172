import React from 'react';
import ScrollToTop from './ScrollToTop';
import Header from './Header';
import SideBar from './SideBar';
import Footer from "./Footer";
import { getSearchParam } from '../config/helpers';
import {withStyles} from '@material-ui/core/styles';
import { connect } from 'react-redux';

const styles = theme => ({
  app: {
    backgroundColor: theme.palette.background.default,
  },
});

class AppLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    componentDidMount(){
        const authenticated = getSearchParam(this.props.location, 'authenticated');
        if (authenticated && authenticated === 'true'){
            this.props.login().then(res => {
                this.props.history.push({
                    pathname: '/',
                 })
            })
        }
    }

    handleCloseSideBar = event => {
        this.setState({open: false})
    };

    handleOpenSideBar = event => {
        this.setState({open: true})
    };

  render() {
      const { classes } = this.props;
      return (
            <ScrollToTop>
              <div className={classes.app}>
                  <header>
                    <Header
                        openSideBar={this.handleOpenSideBar}
                    />
                    {this.props.isLoggedIn &&
                    <SideBar
                        open={this.state.open}
                        handleCloseSideBar={this.handleCloseSideBar}
                    />}
                  </header>
                {this.props.children}
                <Footer/>
              </div>
            </ScrollToTop>
      );
  }
}

function mapStateToProps(state, props) {
  return {
    isLoggedIn: state.auth.isLoggedIn
  };
}

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(AppLayout))
