import React from 'react';
// import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import Button from '@material-ui/core/Button';
import { debounce } from 'throttle-debounce';
import { connect } from 'react-redux';
// import logo from "../assets/logo.png";
import {withStyles} from '@material-ui/core/styles';
import { apiBaseUrl } from '../config/urls';
import {withRouter, Link} from 'react-router-dom';
import { setMode } from '../actions/mode';
import { setSearchValue, searchEl } from '../actions/search';
import { MixPanel } from '../components/MixPanel';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
    title: {
        [theme.breakpoints.up('sm')]: {
          display: 'block',
          opacity: 0.8
        },
        marginRight: theme.spacing(2)
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
      },
      inputRoot: {
        color: 'inherit',
        width: '100%',
        height: 44
      },
    bar: {
        backgroundColor: theme.palette.bar.main
    },
    rightMenu: {
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        maxHeight: 60,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
          marginRight: theme.spacing(1),
        },
    },
    aboutLink:{
        textDecoration: 'none',
        color: theme.palette.text.primary,
        opacity: 0.8,
        "&:hover": {
            opacity: 1,
            textDecoration: 'underline'
        },
        marginRight: theme.spacing(2)
    },
    iconLink: {
        textDecoration: 'none',
    },
    darkIconClass: {
        // marginRight: theme.spacing(1)
    },
    accountIconClass: {
        marginLeft: 'auto',
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
          marginLeft: theme.spacing(1),
          padding: 0
        },
    },
    button: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary.darker,
        color: 'white',
        "&:hover": {
            backgroundColor: theme.palette.primary.main
        },
    },
});

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            inputValue: ''
        };
    }
    componentDidMount() {
        this.onChangeHandle = debounce(
          200,
          this.onChangeHandle
        )
      }
      componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname && this.props.isLoggedIn) {
            this.props.setSearchValue({
                name: this.props.user.name,
                type: 'User',
                id: "https://bipdash.com/user_" + this.props.user.twitter_id,
                profileImageUrl: this.props.user.profile_image_url.replace('_normal', '_bigger')
            })
        }
      }

      onChange = (newValue) => {
          this.props.setSearchValue(newValue)
      }

      onInputChange = (newValue) => {
        this.setState({ inputValue: newValue === undefined? '': newValue })
      }

      onOpenClose = async () => {
         await this.setState({ open: !this.state.open })
         if (!this.state.open) { this.setState({ options: [] })}
      }

      onChangeHandle = async (value) => {
        this.props.searchEl(value)
      }

      disableEnter = (event) => {
       if (event.keyCode === 13) {
           event.preventDefault();
       }
     }

     handleDarkIconClick = (event) => {
       this.props.setMode(!this.props.isDark)
     };

     handleClose = () => {
       this.setState({anchorEl: null});
     };

     handleSearch = (e) => {
         this.props.setSearchValue(e)
         MixPanel.track('Graph - Search ' + e.type, e)
     }

     handleSignIn = () => {
         window.open(apiBaseUrl + "/auth/twitter", "_self");
     }

     handleClick = (event) => {
       this.setState({anchorEl: event.currentTarget});
     };

     handleClose = () => {
       this.setState({anchorEl: null});
     };

     goToSettings = () => {
       this.setState({anchorEl: null});
       this.props.history.push(`/settings`);
     };

     handleLogout = () => {
       this.setState({anchorEl: null});
       window.open(apiBaseUrl + "/auth/logout", "_self");
     };

    render () {
        const { classes } = this.props;
        return(
            <div className={classes.grow}>
              {/* <AppBar position="sticky" className={classes.bar}> */}
                  <Toolbar className={classes.toolbar}>
                      <Link to='/?refresh' className={classes.iconLink}>
                         <Typography className={classes.title} variant="h3" noWrap>
                            Bipdash
                          </Typography>
                      </Link>

                    <div className={classes.rightMenu}>
                        <IconButton
                            className={classes.darkIconClass}
                            onClick={this.handleDarkIconClick}
                        >
                            {this.props.isDark ? <Brightness7Icon/> : <Brightness4Icon />}
                      </IconButton>
                        <Link to='/about' className={classes.aboutLink}> About </Link>
                        {this.props.isLoggedIn ?
                            <div>
                                <IconButton
                                    className={classes.accountIconClass}
                                    onClick={this.handleClick}
                                >
                                  <Avatar
                                    alt={this.props.user.name.toUpperCase()}
                                    src={this.props.user.profile_image_url}
                                    aria-label="open account menu"
                                  >
                                </Avatar>
                                </IconButton>
                              <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={this.handleClose}
                              >
                                <MenuItem onClick={this.goToSettings}>Email Settings</MenuItem>
                                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                              </Menu>
                          </div>
                          : <Button
                             type="submit"
                             variant="contained"
                             size="small"
                             color="primary"
                             className={classes.button}
                             onClick={(e) => this.handleSignIn(e)}
                           >
                             Sign In
                         </Button>}
                    </div>
                </Toolbar>
            {/* </AppBar> */}
          </div>
        )
    };
}

function mapDispatchToProps(dispatch) {
  return {
    setSearchValue: (value) => dispatch(setSearchValue(value)),
    searchEl: (value) => dispatch(searchEl(value)),
    setMode: (isDark) => dispatch(setMode(isDark)),
  };
}

function mapStateToProps(state, props) {
  return {
    instructionText: state.graph.instructionText,
    value: state.search.searchValue ? state.search.searchValue : { name: '', id: '' },
    loading: state.search.loading,
    options: state.search.options,
    user: state.auth.user ? state.auth.user : {twitter_id: '', profile_image_url: ''},
    isLoggedIn: state.auth.isLoggedIn,
    isDark: state.mode.isDark,

  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Header)));
