import {
    FETCH_THREADS_STARTED,
    FETCH_THREADS_SUCCESS,
    FETCH_THREADS_FAILED,
    FETCH_THREAD_USERS_STARTED,
    FETCH_THREAD_USERS_SUCCESS,
    FETCH_THREAD_USERS_FAILED,
    FETCH_THREAD_TOPICS_STARTED,
    FETCH_THREAD_TOPICS_SUCCESS,
    FETCH_THREAD_TOPICS_FAILED,
    UPDATE_THREAD_TOPIC_STARTED,
    UPDATE_THREAD_TOPIC_SUCCESS,
    UPDATE_THREAD_TOPIC_FAILED,
    FETCH_CURRENT_THREAD_STARTED,
    FETCH_CURRENT_THREAD_FAILED,
    FETCH_CURRENT_THREAD_SUCCESS,
    NO_REFRESH,
    REFRESH,
    SET_FILTERS,
} from '../constants/actionTypes'
import { fetchThreadsApi, fetchCurrentThreadApi, fetchThreadUsersApi,
        fetchThreadTopicsApi, updateThreadTopicApi } from '../api/threads';


export function fetchThreads(url, userId) {
  return function (dispatch) {
    dispatch(fetchThreadsStarted())
    return fetchThreadsApi(url, userId)
      .then((response) => {
        if ('errors' in response) {
            console.log({ response })
            dispatch(fetchThreadsFailed(response.errors))
        }
        else {
            // dispatch(setFiltersAndPage({
            //     page: page,
            //     users: users,
            //     search: search,
            //     topic: topic,
            //     sort: sort
            // }))
            dispatch(fetchThreadsSuccess(response))
        }
      })
      .catch(err => {
          dispatch(fetchThreadsFailed(err.message))
      });
  }
}

const fetchThreadsSuccess = data => ({
  type: FETCH_THREADS_SUCCESS,
  payload: {
      data
  }
});

const fetchThreadsStarted = () => ({
  type: FETCH_THREADS_STARTED
});

const fetchThreadsFailed = error => ({
  type: FETCH_THREADS_FAILED,
  payload: {
    error
  }
});

export function fetchCurrentThread(id) {
  return function (dispatch) {
    dispatch(fetchCurrentThreadStarted())
    return fetchCurrentThreadApi(id)
      .then((response) => {
        if ('errors' in response) {
            dispatch(fetchCurrentThreadFailed(response.errors))
        }
        else {
            dispatch(fetchCurrentThreadSuccess(response))
        }
      })
      .catch(err => {
          dispatch(fetchCurrentThreadFailed(err.message))
      });
  }
}

const fetchCurrentThreadSuccess = data => ({
  type: FETCH_CURRENT_THREAD_SUCCESS,
  payload: {
      data
  }
});

const fetchCurrentThreadStarted = () => ({
  type: FETCH_CURRENT_THREAD_STARTED
});

const fetchCurrentThreadFailed = error => ({
  type: FETCH_CURRENT_THREAD_FAILED,
  payload: {
    error
  }
});

export const disableFetch = () => ({
  type: NO_REFRESH
})

export const enableFetch = () => ({
  type: REFRESH
})

export function fetchThreadUsers() {
  return function (dispatch) {
    dispatch(fetchThreadUsersStarted())
    return fetchThreadUsersApi()
      .then((response) => {
        if ('errors' in response) {
            dispatch(fetchThreadUsersFailed(response.errors))
        }
        else {
            dispatch(fetchThreadUsersSuccess(response))
        }
      })
      .catch(err => {
          dispatch(fetchThreadUsersFailed(err.message))
      });
  }
}

const fetchThreadUsersSuccess = data => ({
  type: FETCH_THREAD_USERS_SUCCESS,
  payload: {
      data
  }
});

const fetchThreadUsersStarted = () => ({
  type: FETCH_THREAD_USERS_STARTED
});

const fetchThreadUsersFailed = error => ({
  type: FETCH_THREAD_USERS_FAILED,
  payload: {
    error
  }
});

export const setFiltersAndPage = (data) => ({
    type: SET_FILTERS,
    payload: data
});

export function fetchThreadTopics() {
  return function (dispatch) {
    dispatch(fetchThreadTopicsStarted())
    return fetchThreadTopicsApi()
      .then((response) => {
        if ('errors' in response) {
            dispatch(fetchThreadTopicsFailed(response.errors))
        }
        else {
            dispatch(fetchThreadTopicsSuccess(response))
        }
      })
      .catch(err => {
          dispatch(fetchThreadTopicsFailed(err.message))
      });
  }
}

const fetchThreadTopicsSuccess = data => ({
  type: FETCH_THREAD_TOPICS_SUCCESS,
  payload: {
      data
  }
});

const fetchThreadTopicsStarted = () => ({
  type: FETCH_THREAD_TOPICS_STARTED
});

const fetchThreadTopicsFailed = error => ({
  type: FETCH_THREAD_TOPICS_FAILED,
  payload: {
    error
  }
});


export function updateThreadTopic(id, topic, subtopic) {
  return function (dispatch) {
    dispatch(updateThreadTopicStarted())
    return updateThreadTopicApi(id, topic, subtopic)
      .then((response) => {
        if ('errors' in response) {
            dispatch(updateThreadTopicFailed(response.errors))
        }
        else {
            dispatch(updateThreadTopicSuccess(response))
        }
      })
      .catch(err => {
          dispatch(updateThreadTopicFailed(err.message))
      });
  }
}

const updateThreadTopicSuccess = data => ({
  type: UPDATE_THREAD_TOPIC_SUCCESS,
  payload: {
      data
  }
});

const updateThreadTopicStarted = () => ({
  type: UPDATE_THREAD_TOPIC_STARTED
});

const updateThreadTopicFailed = error => ({
  type: UPDATE_THREAD_TOPIC_FAILED,
  payload: {
    error
  }
});
