import {
    SET_MODE,
} from '../constants/actionTypes';

const initialState = {
  isDark: true,
}

const modeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODE:
      return {
        ...state,
        isDark: action.payload,
    }
    default:
      return state
  }
}

export default modeReducer
