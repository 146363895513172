import React from 'react';
import AppLayout from "./components/AppLayout";
import Settings from "./components/Settings";
import ThreadsGrid from "./components/ThreadsGrid";
import ThreadPage from "./components/ThreadPage";
import About from "./components/About";
import {Route, withRouter, Redirect, Switch} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import {lightTheme, darkTheme} from './theme';
import { connect } from 'react-redux';

//
// const blogUrls = dummyBlogPosts.map((blogPost) => {
//   let title = blogPost.title;
//   title = title.toLowerCase();
//   /* Remove unwanted characters, only accept alphanumeric and space */
//   title = title.replace(/[^A-Za-z0-9 ]/g, "");
//   /* Replace multi spaces with a single space */
//   title = title.replace(/\s{2,}/g, " ");
//   /* Replace space with a '-' symbol */
//   title = title.replace(/\s/g, "-");
//   let url = `/blog/post/${title}`;
//   // blogPost.params = `?id=${blogPost.id}`;
//   return {
//       exact: false,
//       path: url,
//       component: BlogPost
//   };
// });

const routes = [
  {
    exact: true,
    path: '/',
    component: ThreadsGrid
  },
  {
    exact: false,
    path: '/results',
    component: ThreadsGrid
  },
  {
      exact: true,
      path: '/thread/:id',
      component: ThreadPage
  },
  {
      exact: true,
      path: '/settings',
      component: Settings
  },
  {
    exact: false,
    path: '/about',
    component: About
  },
  {
    path: '*',
    // eslint-disable-next-line
    render: () => <Redirect to="/" />
  },
];


class App extends React.Component {
    render() {
      return (
        <ThemeProvider theme={this.props.isDark ? darkTheme : lightTheme}>
            <AppLayout>
                <Switch>
                    {routes.map(route =>
                        <Route key={route.path} {...route} />
                    )}
                </Switch>
            </AppLayout>
        </ThemeProvider>
      );
    }
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

function mapStateToProps(state, props) {
  return {
    isDark: state.mode.isDark,
  };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
