import { apiBaseUrl } from '../config/urls';

export const fetchThreadsApi = async (params, userId) => {
    return fetch(apiBaseUrl + '/api/threads?' + params, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        return response.json()
      })
      .catch(error => {
          return error
      })
}

export const fetchCurrentThreadApi = async (id) => {
    return fetch(apiBaseUrl + '/api/thread/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        return response.json()
      })
      .catch(error => {
          return error
      })
}

export const fetchThreadUsersApi = async () => {
    return fetch(apiBaseUrl + '/api/threads/users', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        return response.json()
      })
      .catch(error => {
          return error
      })
}

export const fetchThreadTopicsApi = async () => {
    return fetch(apiBaseUrl + '/api/threads/topics', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        return response.json()
      })
      .catch(error => {
          return error
      })
}


export const updateThreadTopicApi = async (id, topic, subtopic) => {
    return fetch(apiBaseUrl + '/api/thread/' + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"topic": topic, "subtopic": subtopic})
    })
        .then(res => {
            return res.json();
        })
        .catch(err => {
            return err
        })
}
