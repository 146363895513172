import React from 'react';
import {withRouter} from 'react-router-dom';

export class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if ((this.props.location.pathname === '/' || this.props.location.pathname.startsWith('/results')) && prevProps.location.pathname.startsWith('/thread/')) {
        window.scrollTo(0, 800);
    }
    else if (this.props.location.pathname.startsWith('/thread/') && window) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
