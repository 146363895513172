import {
    UPDATE_USER_LIST_THREADS_READ_STARTED,
    UPDATE_USER_LIST_THREADS_READ_FAILED,
    UPDATE_USER_LIST_THREADS_READ_SUCCESS,
    GET_USER_LIST_THREADS_READ_STARTED,
    GET_USER_LIST_THREADS_READ_FAILED,
    GET_USER_LIST_THREADS_READ_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
    error: null,
    loading: false,
    readThreads: [],
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
      case UPDATE_USER_LIST_THREADS_READ_STARTED:
        return {
          ...state,
          error: null,
          loading: true
      }
      case UPDATE_USER_LIST_THREADS_READ_FAILED:
        return {
          ...state,
          error: action.payload.error,
          loading: false,
      }
      case UPDATE_USER_LIST_THREADS_READ_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
      }
      case GET_USER_LIST_THREADS_READ_STARTED:
        return {
          ...state,
          error: null,
          loading: true
      }
      case GET_USER_LIST_THREADS_READ_FAILED:
        return {
          ...state,
          error: action.payload.error,
          loading: false,
      }
      case GET_USER_LIST_THREADS_READ_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          readThreads: action.payload
      }
    default:
      return state
  }
}

export default userReducer
