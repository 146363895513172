import { apiBaseUrl } from '../config/urls';

export const updateReadListApi = async (threadId, userId) => {
    return fetch(apiBaseUrl + '/api/user/' + userId + '/readThreads', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            thread: threadId
        }),
    }).then(response => response.json())
}

export const getReadListApi = async (userId) => {
    return fetch(apiBaseUrl + '/api/user/' + userId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(response => response.json())
}
