import React from 'react';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { Typography, Card, Box, Container, Button } from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import {withStyles} from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { MixPanel } from '../components/MixPanel';
import { fetchThreads, enableFetch, fetchThreadUsers, fetchThreadTopics} from '../actions/threads';
import { color_hashtags_urls_mentions_in_threads, getSearchParam } from '../config/helpers';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Icon } from '@iconify/react';
import repeatVariant from '@iconify-icons/mdi/repeat-variant';
import cardsHeart from '@iconify-icons/mdi/cards-heart';
import commentIcon from '@iconify-icons/mdi/comment';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import Chip from '@material-ui/core/Chip';
import Hidden from '@material-ui/core/Hidden';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import {ListboxComponent} from './ListboxComponent';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom';
import CTACard from './CTACard';
import { withLastLocation } from 'react-router-last-location';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { loginUser, clearSession } from '../actions/auth';


const styles = theme => ({
    app: {
      flexGrow: 1,
    },
    container: {
        paddingTop: theme.spacing(4),
        maxWidth: 1400,
        paddingBottom: theme.spacing(8),
    },
    containerIntro: {
        paddingTop: theme.spacing(4),
        maxWidth: 1400,
    },
    author: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing(4)
    },
    avatar: {
        marginRight: theme.spacing(1)
    },
    name: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 160,
        [theme.breakpoints.only('xs')]: {
            maxWidth: 140,
        },
    },
    date: {
        marginLeft: 'auto',
        color: theme.palette.primary.other
    },
    cardContent: {
        height: 230,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    button: {
        opacity: 0.8,
        "&:hover": {
            opacity: 1,
            color: theme.palette.primary.main
        }
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    authorLink: {
        textDecoration: 'none',
        opacity: 0.8,
        "&:hover": {
            opacity: 1
        }
    },
    paginationDiv: {
        display: 'flex',
        marginBottom: theme.spacing(2)
    },
    paginationDivDown: {
        display: 'flex',
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    pagination: {
        marginLeft: 'auto',
        marginTop: theme.spacing(2)
    },
    ul: {
        "& .MuiPaginationItem-root": {
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ]
        }
    },

    authorname: {
        fontWeight: 'bold',
        opacity: 0.8,
        "&:hover": {
            opacity: 1
        }
    },
    buttonDiv: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    socialDiv: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    iconLikeDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        "&:hover": {
            "& $iconLike": {
                color: '#FE251B',
                opacity: 1
            },
            "& $likeDigit": {
                color: '#FE251B',
                opacity: 1
              }
        },
    },
    iconRetweetDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        "&:hover": {
            "& $iconRetweet": {
                color: '#19cf86',
                opacity: 1
            },
            "& $retweetDigit": {
                color: '#19cf86',
                opacity: 1
              }
        },
    },
    iconReplyDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        "&:hover": {
            "& $iconReply": {
                color: '#00acee',
                opacity: 1
            },
            "& $replyDigit": {
                color: '#00acee',
                opacity: 1
              }
        },
    },
    iconLike: {
        opacity: 0.6
    },
    iconRetweet: {
        opacity: 0.6
    },
    iconReply: {
        opacity: 0.6
    },
    retweetDigit: {
        marginLeft: theme.spacing(1),
        opacity: 0.6
    },
    likeDigit: {
        marginLeft: theme.spacing(1),
        opacity: 0.6
    },
    replyDigit: {
        marginLeft: theme.spacing(1),
        opacity: 0.6
    },
    leftHeader: {
        display: 'flex',
        marginLeft: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        background: '-webkit-linear-gradient(' + theme.palette.primary.other +',' +  theme.palette.primary.main + ')',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        marginBottom: theme.spacing(2),
    },
    textfield: {
        opacity: 0.8,
    },
    gridElt: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    link: {
        textDecoration: 'none',
        "&:hover": {
            color: "#222"
        }
    },
    paginationResults: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center'
    },
    paginationResultsRow: {
        display: 'flex',
        flex:1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    gridContainer: {
        maxWidth: 1400
    },
   containerHero: {
       paddingLeft: theme.spacing(8),
       [theme.breakpoints.down('md')]: {
           paddingLeft: theme.spacing(2)
       },
       marginTop: theme.spacing(4),
       marginBottom: theme.spacing(4)
   },
   highlightHero: {
      position: 'absolute',
      backgroundColor: theme.palette.primary.highlight,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      fontWeight: 'bold',
      transform: 'skew(-3deg)',
   },
   subtitle: {
       position: 'relative',
   },
   highlightedText: {
       position: 'relative',
       display: 'inline-block',
       margin:0,
       zIndex:1,
       fontWeight: 'bold',
   },
   centerSubtext: {
       display: 'flex',
       justifyContent: 'center'
   },
   // filters
   smallAvatar: {
       marginLeft: theme.spacing(1),
       marginRight: theme.spacing(1),
       width: theme.spacing(3),
       height: theme.spacing(3),
   },
   autocomplete: {
       [theme.breakpoints.only('xs')]: {
           maxWidth: 300,
       },
       maxWidth: 360,
       width: '100%',
   },
   iconSelected: {
       width: 17,
       height: 17,
       marginRight: 5,
       marginLeft: -2,
   },
   optionText: {
       flexGrow: 1,
   },
   filterContainer: {
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'center',
       justifyContent: 'center',
       marginBottom: theme.spacing(4),
       maxWidth: 1400,
       padding: theme.spacing(2),
       margin: 0
   },
   filterRow: {
       display: 'flex',
       [theme.breakpoints.down('md')]: {
           flexDirection: 'column',
       },
       flexDirection: 'row',
       alignItems: 'center',
       justifyContent: 'space-between',
       paddingLeft: 0,
       paddingRight: 0,
       marginBottom: theme.spacing(2),
   },
   searchBar: {
       [theme.breakpoints.only('xs')]: {
           maxWidth: 300,
           marginRight: 0
       },
       maxWidth: 500,
       width: '100%',
       marginRight: theme.spacing(8)
   },
   containerSearch: {
       display: 'flex',
       [theme.breakpoints.down('md')]: {
           flexDirection: 'column',
       },
       flexDirection: 'column',
       alignItems: 'center',
       marginBottom: theme.spacing(2)
   },
   clearButton: {
       paddingRight: 0
   },
   topics: {
       display: 'flex',
       justifyContent: 'center',
       flexDirection: 'row',
       flexWrap: 'wrap',
       listStyle: 'none',
       marginTop: theme.spacing(4),
       padding: theme.spacing(0.5),
  },
  formControl: {
      marginRight: theme.spacing(3),
      minWidth: 300,
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
  },
  formControlSort: {
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(1.5),
      minWidth: 300,
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
  },
  formControlTopic: {
      width: 360,
      [theme.breakpoints.down('md')]: {
          width: 280
      },
  },
  sortIcon: {
      marginTop: theme.spacing(1)
  },
  label: {
      marginLeft: theme.spacing(2),
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


class ThreadsGrid extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          page: 1,
          loading: false,
          users: [],
          search: '',
          topic: [],
          subtopics: {
              "61128e4885f0f48b46628b7b": [],
              "61128e4885f0f48b46628b7c": [],
              "6119c82638c6b61fe4927203": [],
              "61128e4885f0f48b46628b80": [],
              "61307c1178a3eb3cc77f7625": [],
              "61307fc278a3eb3cc77f7626": [],
              "61c33645eda039a11b578b15": [],
              "61c335fceda039a11b578b14": [],
          },
          subtopicsOpen: {
              "61128e4885f0f48b46628b7b": false,
              "61128e4885f0f48b46628b7c": false,
              "6119c82638c6b61fe4927203": false,
              "61128e4885f0f48b46628b80": false,
              "61307c1178a3eb3cc77f7625": false,
              "61307fc278a3eb3cc77f7626": false,
              "61c33645eda039a11b578b15": false,
              "61c335fceda039a11b578b14": false,
          },
          sortArrow: 'down',
          sortField: 'created_at',
          totalThreadsInDB: 17000,
          excludeRead: 'false',
      };
  }

  componentDidMount() {
      // check login
      const authenticated = getSearchParam(this.props.location, 'authenticated');
      if (authenticated) {
          if (authenticated === 'true'){
              this.props.login()
          } else {
              this.props.logout()
          }
      }
      // start function
      if (this.props.location.pathname === '/' && (!this.props.lastLocation || this.props.location.search === '?refresh')){
          this.setState({ loading: true })
          const params = `page=1&sortField=created_at&sortDirection=down&excludeRead=${this.state.excludeRead}&userId=${this.props.user.twitter_id}`
          this.props.fetchThreads(params).then(res => {
              this.props.fetchUsers()
              this.props.fetchTopics()
          }).then(res => {
              this.setState({ loading: false, totalThreadsInDB: this.props.data.total })
          });
      } else {
          if (this.props.location.pathname.startsWith('/results') && !(this.props.lastLocation && this.props.lastLocation.pathname.startsWith('/thread/'))){
              this.props.fetchThreads(this.props.location.search.split('?')[1] + `&userId=${this.props.user.twitter_id}`).then(res => {
                  this.props.fetchUsers()
                  this.props.fetchTopics()
              }).then(res => {
                  this.setDataFiltersAndPages()
              })
          } else {
              this.setDataFiltersAndPages()
          }

      }
      MixPanel.track('View Thread Grid')
  }

  componentDidUpdate(prevProps){
      if (this.props.location !== prevProps.location){
          this.props.fetchThreads(this.props.location.search.split('?')[1] + `&userId=${this.props.user.twitter_id}`).then(res => {
              this.props.fetchUsers()
              this.props.fetchTopics()
          })
      }
  }


  setDataFiltersAndPages = () => {
      const topics = getSearchParam(this.props.location, 'topic') ? getSearchParam(this.props.location, 'topic').split(',') : []
      const users = getSearchParam(this.props.location, 'users') ? getSearchParam(this.props.location, 'users').split(',') : []
      const page = getSearchParam(this.props.location, 'page') ? Number(getSearchParam(this.props.location, 'page')) : this.state.page;
      const search = getSearchParam(this.props.location, 'search') ? getSearchParam(this.props.location, 'search') : ''
      const field = getSearchParam(this.props.location, 'field') ? getSearchParam(this.props.location, 'sortField'): 'created_at'
      const direction = getSearchParam(this.props.location, 'direction') ? getSearchParam(this.props.location, 'sortDirection'): 'down'
      const excludeRead = getSearchParam(this.props.location, 'excludeRead') ? getSearchParam(this.props.location, 'excludeRead'): 'false'

      const initialTopics = this.props.initialTopics;
      let subtopics = this.state.subtopics;

      Object.keys(subtopics).map((key, index) => {
          subtopics[key] = initialTopics.filter(t => t._id === key)[0]
            .subtopics.map(s => s._id)
            .filter(s => topics.includes(s));
          return null;
        });
      this.setState({
          users: this.props.initialUsers.filter(u => users.includes(u.user_id)),
          topic: topics,
          subtopics: topics ? subtopics: {
              "61128e4885f0f48b46628b7b": [],
              "61128e4885f0f48b46628b7c": [],
              "6119c82638c6b61fe4927203": [],
              "61128e4885f0f48b46628b80": [],
              "61307c1178a3eb3cc77f7625": [],
              "61307fc278a3eb3cc77f7626": [],
              "61c33645eda039a11b578b15": [],
              "61c335fceda039a11b578b14": [],
          },
          page: page,
          search: search,
          sortArrow: direction,
          sortField: field,
          excludeRead: excludeRead,
      })
  }

  handlePageChange = (event, value) => {
      MixPanel.track('View Thread Grid - Change Page', {Page: value})
      this.setState({ page: value })
      const url = this.makeURL(
          value,
          this.state.users,
          this.state.search,
          this.state.topic,
          this.state.sortField,
          this.state.sortArrow,
          this.state.excludeRead,
      )
      this.props.history.push(url);
  }

  handleUserChange = (event, newValue) => {
      MixPanel.track('View Thread Grid - Filter User', {Users: newValue})
      this.setState({users: newValue, page:1})
      const url = this.makeURL(
          1,
          newValue,
          this.state.search,
          this.state.topic,
          this.state.sortField,
          this.state.sortArrow,
          this.state.excludeRead,
      )
      this.props.history.push(url);
  };

  handleSearchOnChange = (event) => {
      const { value, name } = event.target;
      this.setState({
        [name]: value
      });
  }

  handleSearchClick = () => {
      MixPanel.track('View Thread Grid - Search', {'Search String': this.state.search})
      this.setState({ page: 1 })
      const url = this.makeURL(
          1,
          this.state.users,
          this.state.search,
          this.state.topic,
          this.state.sortField,
          this.state.sortArrow,
          this.state.excludeRead,
      )
      this.props.history.push(url);
  }

  handleSearchkeyPress = (e) => {
      if(e.keyCode === 13){
          MixPanel.track('View Thread Grid - Search', {'Search String': this.state.search})
          const url = this.makeURL(
              1,
              this.state.users,
              this.state.search,
              this.state.topic,
              this.state.sortField,
              this.state.sortArrow,
              this.state.excludeRead,
          )
          this.props.history.push(url);
      }
   }

  handleClearIcon = () => {
      this.setState({search: '', page: 1})
      const url = this.makeURL(
          1,
          this.state.users,
          '',
          this.state.topic,
          this.state.sortField,
          this.state.sortArrow,
          this.state.excludeRead,
      )
      this.props.history.push(url);
  }

  handleTopicFiltering = (topic_id, topic_name) => {
      const topic = this.state.topic.includes(topic_id) ? this.state.topic.filter(item => item !== topic_id) : this.state.topic.concat([topic_id]);
      this.setState({ topic: topic, page: 1 })
      const url = this.makeURL(
          1,
          this.state.users,
          this.state.search,
          topic,
          this.state.sortField,
          this.state.sortArrow,
          this.state.excludeRead,
      )
      this.props.history.push(url);
      if (topic.length > 0){
          MixPanel.track('View Thread Grid - Topic', {'Topic': topic_name})
      }
  }


  handleSubtopicFiltering = (event, elem) => {
      const value = [...event.target.value].pop()
      const subtopics = {...this.state.subtopics, ...{[elem._id]: event.target.value}}
      let subtopicsOpen = this.state.subtopicsOpen;
      subtopicsOpen[elem._id] = false;
      const topics = Object.values(subtopics).flat();
      this.setState({ topic: topics, subtopics: subtopics, page: 1, subtopicsOpen: subtopicsOpen })
      const url = this.makeURL(
          1,
          this.state.users,
          this.state.search,
          topics,
          this.state.sortField,
          this.state.sortArrow,
          this.state.excludeRead,
      )
      this.props.history.push(url);
      if (event.target.value.length > 0){
          MixPanel.track('View Thread Grid - Topic', {'Topic': elem.topic, 'Subtopic': elem.subtopics.filter(sub => sub._id === value)[0].subtopic})
      }
  }

  openSubtopics = (topic) => {
      let subtopicsOpen = this.state.subtopicsOpen;
      subtopicsOpen[topic] = true;
      this.setState({subtopicsOpen: subtopicsOpen})
  }

  closeSubtopics = (topic) => {
      let subtopicsOpen = this.state.subtopicsOpen;
      subtopicsOpen[topic] = false;
      this.setState({subtopicsOpen: subtopicsOpen})
  }

  handleSortIconClick = () => {
      this.setState({sortArrow: this.state.sortArrow === 'up' ? 'down' : 'up'})
      const url = this.makeURL(
          1,
          this.state.users,
          this.state.search,
          this.state.topic,
          this.state.sortField,
          this.state.sortArrow === 'up' ? 'down' : 'up',
          this.state.excludeRead,
      )
      this.props.history.push(url);
  }

  handleSortFieldClick = (event) => {
      this.setState({sortField: event.target.value})
      MixPanel.track('View Thread Grid - Sort', {'Sort': event.target.value})
      const url = this.makeURL(
          1,
          this.state.users,
          this.state.search,
          this.state.topic,
          event.target.value,
          this.state.sortArrow,
          this.state.excludeRead,
      )
      this.props.history.push(url);
  }

  makeURL = (page, users, search, topic, field, direction, excludeRead) => {
      const users_ids = users.map(u => u.user_id)
      let params = 'page=' + page;
      if (users && users.length>0){
          params = params + '&users=' + users_ids.join()
      }
      if (search !== ''){
          params = params + '&search=' + encodeURI(search)
      }
      if (topic && topic.length>0){
          params = params + '&topic=' + topic.join()
      }
      params = params + '&sortField=' + field + '&sortDirection=' + direction + '&excludeRead=' + excludeRead
      return `/results?${params}`
  }

  handleClickSeeOnTwitter = (e, elem) => {
      e.stopPropagation();
      MixPanel.track('See On Twitter', {'Tweet ID': elem.tweets[0].tweet_id})
      window.open('https://twitter.com/bipdash/status/' + elem.tweets[0].tweet_id)
  }

  handleExcludeAlreadyReadChange = (e) => {
      this.setState({excludeRead: e.target.checked ? 'true': 'false'})
      MixPanel.track('View Thread Grid - Exclude Read Threads', {'Exclude': e.target.checked})
      const url = this.makeURL(
          1,
          this.state.users,
          this.state.search,
          this.state.topic,
          this.state.sortField,
          this.state.sortArrow,
          e.target.checked ? 'true': 'false',
      )
      this.props.history.push(url);
  }

  render () {
    const { classes, theme } = this.props;
    let threads = this.props.data.threads ? this.props.data.threads : [];
    const pages = Math.ceil(this.props.data.total / 12);
    const page = pages === 1 ? 1 : this.state.page;
    return(

        <div className={classes.app}>
            <Container maxWidth="lg" className={classes.containerIntro}>
                <Typography variant='h2' align='center' className={classes.title}>
                    Library of the creator economy
                </Typography>
                <Container maxWidth='md' className={classes.containerHero}>
                    <Typography variant='h5' align='left' display="inline">
                        <span role="img" aria-label="thread">💎 </span> {Math.floor(this.state.totalThreadsInDB/1000)*1000}+{' '}
                    </Typography>
                    <Typography component='div' variant='h5' align='left' display="inline" className={classes.subtitle}>
                        <span className={classes.highlightHero}></span>
                        <p className={classes.highlightedText}>Twitter threads</p>
                    </Typography>
                    <Typography variant='h5' display="inline" align='left'>
                        , and 30+ being added everyday.
                    </Typography>
                    <br/>
                    <Typography variant='h5' display="inline" align='left'>
                         <span role="img" aria-label="thread">💎 </span>
                    </Typography>
                    <Typography component='div' variant='h5' align='left' display="inline" className={classes.subtitle}>
                        <span className={classes.highlightHero}></span>
                        <p className={classes.highlightedText}>Receive a weekly email</p>
                    </Typography>
                    <Typography variant='h5' align='left' display="inline">
                         {' '}curating the best threads on topics you care about!
                    </Typography>
                    <br/>
                    <Typography variant='h5' align='left'>
                        <span role="img" aria-label="thread">💎</span> Learn from the best of Twitter - without going on Twitter.
                    </Typography>
                    <div className={classes.centerSubtext}>
                    <Typography component='div' variant='h5' align='center' display="inline" className={classes.subtitle}>
                        <span className={classes.highlightHero}></span>
                        <p className={classes.highlightedText}>All of the Signal, None of the noise</p>
                    </Typography>
                    </div>
                </Container>
                {!(this.props.isLoggedIn && this.props.user.topics.length > 0) && <CTACard />}
            </Container>
          <Container display='flex' className={classes.container}>
              <Container className={classes.filterContainer}>
                    <Container maxWidth="lg" className={classes.containerSearch}>
                        {process.env.REACT_APP_USER_ADMIN === '1' && <ul className={classes.topics}>
                          {this.props.initialTopics.map((data) => {
                            return (
                              <li key={data._id}>
                                <Chip
                                  key={data._id}
                                  label={data.topic}
                                  style={{
                                      borderColor: data.backgroundColor,
                                      margin: theme.spacing(1),
                                      backgroundColor: this.state.topic.includes(data._id) ? data.backgroundColor : theme.palette.paper.other,
                                      color: this.state.topic.includes(data._id) ? data.color : theme.palette.text.primary,
                                  }}
                                  onClick={(event) => this.handleTopicFiltering(data._id, data.topic)}
                                  variant="outlined"
                                />
                              </li>
                            );
                          })}
                      </ul>}
                      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                         {this.props.initialTopics.filter(i => i.active === true).map(elem => (
                                   <Grid item xs={12} sm={6} md={4}  key={elem._id}>
                          <FormControl className={classes.formControlTopic}>
                            <InputLabel id={"demo-multiple-chip-label" + elem._id} className={classes.label}>{elem.topic}</InputLabel>
                            <Select
                              labelId={"demo-multiple-chip-label" + elem._id}
                              id={"demo-multiple-chip" + elem._id}
                              multiple
                              value={this.state.subtopics[elem._id]}
                              onChange={(event) => this.handleSubtopicFiltering(event, elem)}
                              input={<OutlinedInput id={"select-multiple-chip-" + elem._id} label="Chip" />}
                              open={this.state.subtopicsOpen[elem._id]}
                              onOpen={() => this.openSubtopics(elem._id)}
                              onClose={() => this.closeSubtopics(elem._id)}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip
                                        key={value}
                                        label={elem.subtopics.filter(sub => sub._id === value)[0].subtopic}
                                        style={{
                                            borderColor: elem.backgroundColor,
                                            margin: 2,
                                            backgroundColor: elem.backgroundColor,
                                            color: elem.color,
                                        }} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                                {
                                    elem.subtopics.map(topic => (
                                    <MenuItem key={topic._id} value={topic._id}>{topic.subtopic}</MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>))}
                    </Grid>
                    </Container>
                    <Container className={classes.filterRow}>
                        <TextField
                          label="Text search"
                          autoComplete='off'
                          name="search"
                          value={this.state.search}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                  {this.state.search && <IconButton className={classes.clearButton} size="small" onClick={this.handleClearIcon}>
                                    <ClearIcon />
                                </IconButton>}
                                <IconButton onClick={this.handleSearchClick}>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                          ),
                          }}
                          onChange={this.handleSearchOnChange}
                          onKeyDown={this.handleSearchkeyPress}
                          className={classes.searchBar}
                        />
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={this.props.initialUsers}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) => option._id === value._id}
                          value={this.state.users}
                          onChange={(event, newValue) => this.handleUserChange(event, newValue)}
                          className={classes.autocomplete}
                          ListboxComponent={ListboxComponent}
                          renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <DoneIcon
                                  className={classes.iconSelected}
                                  style={{ visibility: selected ? 'visible' : 'hidden' }}
                                />
                                <Avatar
                                  alt={option.name.toUpperCase()}
                                  src={option.profile_image_url.replace('_normal', '_bigger')}
                                  className={classes.smallAvatar}
                              />
                                <div className={classes.optionText}>
                                  {option.name}
                                </div>
                              </React.Fragment>
                            )}
                            renderTags={(tagValue, getTagProps) => {
                              return tagValue.map((option, index) => (
                                <Chip
                                    {...getTagProps({ index })}
                                    avatar={<Avatar alt={option.name.toUpperCase()} src={option.profile_image_url.replace('_normal', '_bigger')} />}
                                    label={option.name}
                                    size="medium"
                                />
                              ));
                            }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Authors"
                            />
                          )}
                          disabled={this.props.loading}
                        />
                        <FormControl className={classes.formControlSort}>
                            <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={this.state.sortField}
                              onChange={this.handleSortFieldClick}
                              style={{width: 200}}
                            >
                              <MenuItem value="created_at">
                                Created Date
                              </MenuItem>
                              <MenuItem value="engagement_score">
                                Engagement Score
                              </MenuItem>
                            </Select>
                            <IconButton className={classes.sortIcon} onClick={this.handleSortIconClick}>
                              {this.state.sortArrow === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                            </IconButton>
                        </FormControl>
                      </Container>
                      {this.props.isLoggedIn && <FormControlLabel
                          control={
                              <Checkbox
                                  checked={this.state.excludeRead === 'true'}
                                  onChange={this.handleExcludeAlreadyReadChange}
                                  color="primary"
                              />
                          }
                          label="Exclude already read"
                      />}
                </Container>
            <div className={classes.paginationDiv}>
                <Hidden smDown>
                    <div className={classes.paginationResultsRow}>
                        {this.props.loading ?
                            <Typography variant="h6" component="div" className={classes.textfield}>
                                <CircularProgress display="inline" color='primary' size={20}/> Updating...
                            </Typography>
                            :
                            <Typography variant="h6" className={classes.textfield}>
                                Thread count: {this.props.data.total}
                            </Typography>}
                        <Pagination
                            count={pages}
                            color="primary"
                            size="large"
                            classes={{ ul: classes.ul }}
                            className={classes.pagination}
                            page={page}
                            onChange={this.handlePageChange}
                            disabled={this.props.loading}
                        />
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <div className={classes.paginationResults}>
                        {this.props.loading ?
                            <Typography variant="body1" component="div" className={classes.textfield}>
                                <CircularProgress display="inline" color='primary' size={20}/> Updating...
                            </Typography>
                            :
                            <Typography variant="body1" className={classes.textfield}>
                                Thread count: {this.props.data.total}
                            </Typography>}
                        <Pagination
                            count={pages}
                            color="primary"
                            size="small"
                            classes={{ ul: classes.ul }}
                            className={classes.pagination}
                            page={page}
                            onChange={this.handlePageChange}
                            disabled={this.props.loading}
                        />
                    </div>
                </Hidden>
            </div>
            {!this.state.loading && <Grid container direction="row" spacing={2} className={classes.gridContainer}>
               {threads.map(elem => (
                     <Grid item xs={12} sm={6} md={3} key={elem._id} className={classes.gridElt}>
                         <Card className={"MuiThreadCard--01"}>

                                 <CardContent className={classes.cardContent}>
                                    <a target="_blank" rel="noopener noreferrer" href={"https://twitter.com/" + elem.author.username} className={classes.authorLink}>
                                        <div className={classes.author}>
                                             <Avatar
                                               alt={elem.author.name.toUpperCase()}
                                               src={elem.author.profile_image_url.replace('_normal', '_bigger')}
                                               aria-label="open account menu"
                                               className={classes.avatar}
                                           />
                                           <div className={classes.name}>
                                               <Typography variant='subtitle1' className={classes.authorname} noWrap>
                                                   {elem.author.name}
                                               </Typography>
                                               <Typography variant='caption' className={classes.username}>
                                                   {'@' + elem.author.username}
                                               </Typography>
                                           </div>
                                           <div className={classes.leftHeader}>
                                               <Typography variant='subtitle2' className={classes.date}>
                                                   {moment(elem.created_at).fromNow()}
                                               </Typography>
                                           </div>
                                       </div>
                                   </a>
                                   <CardActionArea
                                       component={Link}
                                       to={{
                                          pathname: `/thread/${elem._id.split('_')[1]}`,
                                          thread: elem,
                                      }}
                                    >
                                   <div className={classes.tweetBody}>
                                   {color_hashtags_urls_mentions_in_threads(elem.tweets[0].text).map(e => (
                                       e[0] === '%' ? <Typography variant='body2' display="inline" key={uuidv4()} style={{whiteSpace: 'pre-wrap', color: theme.palette.primary.other}}>
                                           {e.substring(1)}
                                       </Typography>
                                   :<Typography variant='body2' display="inline" key={uuidv4()} style={{whiteSpace: 'pre-wrap'}}>
                                       {e}
                                   </Typography>)
                                   )}
                                   </div>
                                   </CardActionArea>
                                 </CardContent>

                             <CardActions className={classes.cardActions}>
                                 <div>
                                     <Chip
                                       label={'topic' in elem && 'subtopic' in elem ? elem.topic.topic + ' > ' + elem.subtopic.subtopic
                                                                                    : 'topic' in elem ? elem.topic.topic : ""}
                                       style={{
                                           backgroundColor: 'topic' in elem ? elem.topic.backgroundColor: "#111",
                                           margin: theme.spacing(1),
                                           visibility: 'topic' in elem && 'active' in elem.topic && elem.topic.active ? 'visible': 'hidden',
                                           color: 'topic' in elem ? elem.topic.color: "#111111",
                                       }}
                                     />
                                 </div>
                                 <div className={classes.socialDiv}>
                                     <div className={classes.iconReplyDiv}>
                                         <Icon icon={commentIcon} className={classes.iconReply} width="1.1em"/>
                                         <Typography variant='subtitle2' className={classes.replyDigit}>{elem.tweets[0].reply_count}</Typography>
                                     </div>
                                     <div className={classes.iconRetweetDiv}>
                                         <Icon icon={repeatVariant} className={classes.iconRetweet} width="1.5em" />
                                         <Typography variant='subtitle2' className={classes.retweetDigit}>{elem.tweets[0].retweet_count}</Typography>
                                     </div>
                                     <div className={classes.iconLikeDiv}>
                                         <Icon icon={cardsHeart} className={classes.iconLike} width="1.2em"/>
                                         <Typography variant='subtitle2' className={classes.likeDigit}>{elem.tweets[0].like_count}</Typography>
                                     </div>
                                 </div>
                                 <div className={classes.buttonDiv}>
                                     <Button className={classes.button} onClick={(e) => this.handleClickSeeOnTwitter(e, elem)}>
                                        See on Twitter
                                     </Button>
                                     <Link
                                         to={{
                                            pathname: `/thread/${elem._id.split('_')[1]}`,
                                            thread: elem,
                                        }}
                                        className={classes.link}
                                        >

                                         <Button className={classes.button}>
                                           Open
                                         </Button>
                                     </Link>
                                 </div>
                             </CardActions>
                         </Card>
                     </Grid>)
                 )}

            </Grid>}
            {threads.length > 4 && <div className={classes.paginationDivDown}>
                <Hidden smDown>
                    <Pagination
                        count={pages}
                        color="primary"
                        size="large"
                        classes={{ ul: classes.ul }}
                        className={classes.pagination}
                        page={page}
                        onChange={this.handlePageChange}
                        disabled={this.props.loading}
                    />
                </Hidden>
                <Hidden mdUp>
                    <Pagination
                        count={pages}
                        color="primary"
                        size="small"
                        classes={{ ul: classes.ul }}
                        className={classes.pagination}
                        page={page}
                        onChange={this.handlePageChange}
                        disabled={this.props.loading}
                    />
                </Hidden>
            </div>}
        </Container>
        </div>
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchThreads: (url) => dispatch(fetchThreads(url)),
    enableFetch: () => dispatch(enableFetch()),
    fetchUsers: () => dispatch(fetchThreadUsers()),
    fetchTopics: () => dispatch(fetchThreadTopics()),
    login: () => dispatch(loginUser()),
    logout: () => dispatch(clearSession()),
  };
}

function mapStateToProps(state, props) {
  return {
    loading: state.threads.loading,
    data: state.threads.data ? state.threads.data : {threads: [], page: 1, pageSize: 12, total: 100, lastRunDate:"2021-01-01T12:00:00"},
    initialUsers: state.threads.initialUsers ? state.threads.initialUsers : [],
    initialTopics: state.threads.initialTopics ? state.threads.initialTopics : [],
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user? state.auth.user: {twitter_id: '', topics: []},
  };
}

export default withLastLocation(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ThreadsGrid)))
