import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from "react-router-dom";
import DescriptionIcon from '@material-ui/icons/Description';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Avatar from '@material-ui/core/Avatar';
import HomeIcon from '@material-ui/icons/Home';
import { connect } from 'react-redux';
import { apiBaseUrl } from '../config/urls';
import { doClearSession } from '../actions/auth';
import { MixPanel } from '../components/MixPanel';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  text: {
      color: 'white'
  },
  icon: {
      color: 'white'
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#333'
  },
  titleLink: {
    textDecoration: 'none'
  },
  avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: 79
  }
});

class SideBar extends Component {

  handleLogoutClick = async () => {
      // Logout using Twitter passport api
      await this.props.logout();
      window.open(apiBaseUrl + "/auth/logout", "_self");
      MixPanel.track('Sign Out')

    };

 handleClick = (item) => {
        if (item === "Home") {
            this.props.history.push('/')
        }
        if (item === "Browse Threads") {
            this.props.history.push('/threads')
        }
        if (item === "Blogs") {
            this.props.history.push('/blogs')
        }
        if (item === "Sign Out") {
            this.handleLogoutClick()
        }
        this.props.handleCloseSideBar()
    }
render(){
    const { classes } = this.props;
    return (
          <SwipeableDrawer
            className={classes.drawer}
            anchor="right"
            open={this.props.open}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={this.props.handleCloseSideBar}
            onOpen={this.props.handleCloseSideBar}
          >
             <div className={classes.avatarContainer}>
                <Avatar
                    alt={this.props.user.name.toUpperCase()}
                    src={this.props.user.profile_image_url.replace('_normal', '_bigger')}
                    aria-label="open account menu"
                />
            </div>
            <List>
                <ListItem
                    button
                    key='Home'
                    onClick={() => this.handleClick('Home')}
                >
                  <ListItemIcon className={classes.icon}><HomeIcon /></ListItemIcon>
                  <ListItemText primary='Home' className={classes.text} />
                </ListItem>
                <ListItem
                    button
                    key='Browse Threads'
                    onClick={() => this.handleClick('Browse Threads')}
                >
                  <ListItemIcon className={classes.icon}><SearchIcon /></ListItemIcon>
                  <ListItemText primary='Browse Threads' className={classes.text} />
              </ListItem>
            </List>
            <List>
                <ListItem
                    button
                    key='Blogs'
                    onClick={() => this.handleClick('Blogs')}
                >
                  <ListItemIcon className={classes.icon}>{<DescriptionIcon />}</ListItemIcon>
                  <ListItemText primary='Blogs' className={classes.text} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem
                    button
                    key='Sign Out'
                    onClick={() => this.handleClick('Sign Out')}
                >
                  <ListItemIcon className={classes.icon}>{<ExitToAppIcon />}</ListItemIcon>
                  <ListItemText primary='Sign Out' className={classes.text} />
                </ListItem>
            </List>
          </SwipeableDrawer>
      );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(doClearSession())
  };
}

function mapStateToProps(state, props) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(SideBar)));
