import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import CardMedia from '@material-ui/core/CardMedia';

export const parameterizedString = (...args) => {
  const str = args[0];
  const params = args.filter((arg, index) => index !== 0);
  if (!str) return "";
  return str.replace(/%s[0-9]+/g, matchedStr => {
    const variableIndex = matchedStr.replace("%s", "") - 1;
    return params[variableIndex];
  });
}

export const color_hashtags_urls_mentions = (text) => {
    return text.replace(/#(\w+)/g, '#$1')
               .replace(/@(\w+)/g, '@$1')
}

export const color_hashtags_urls_mentions_in_threads = (text) => {
    return text.replace(/#(\w+)/g, '|%#$1|')
               .replace(/@(\w+)/g, '|%@$1|')
               .replace(/\b((http|https)?:\/\/(?:www\.|(?!www))[^\s.]+\.[^\s]{2,}|www\.[^\s]+.[^\s]{2,})\b/g, '|%$1|')
               .split('|')
}

export const createHTMLTitle = (html) => {
    var element = document.createElement("div");
    element.innerHTML = html;
    return element;
}

export const getSearchParam = (location, name) => {
  if (!location || !location.search) {
    return null;
  }
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(name);
};

export const tweet_embedder = (url, theme, classes) => {
    if (url) {
        if (url.expanded_url.includes('https://twitter.com') && url.expanded_url.includes('/status/')){
            if (url.expanded_url.includes('/photo/')){
                return <CardMedia src={url.image_urls[0]} component="img" alt="photo" style={{paddingTop: 10, border: 'none'}}/>;
            }
            return <div className={classes.twitterCenter}>
                <TwitterTweetEmbed
                    options={{ theme: theme }}
                    tweetId={url.expanded_url.split('/').pop().split('?')[0]}
                /></div>
        } else {
            return url.expanded_url;
        }
    } else {
        return '';
    }
}
