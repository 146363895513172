import { combineReducers } from 'redux';
import authReducer from './auth';
import subscribeReducer from './subscribe';
import searchReducer from './search';
import modeReducer from './mode';
import graphReducer from './graph';
import threadsReducer from './threads';
import settingsReducer from './settings';
import userReducer from './user';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['error', 'isLoggedIn']
};

export default combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  subscribe: subscribeReducer,
  graph: graphReducer,
  search: searchReducer,
  threads: threadsReducer,
  mode: modeReducer,
  settings: settingsReducer,
  user: userReducer,
})
