import { apiBaseUrl } from '../config/urls';

export const loginApi = async () => {
    return fetch(apiBaseUrl + "/auth/login/success", {
        method: "GET",
        credentials: "include",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        }
      })
        .then(response => {
          if (response.status === 200) return response.json();
          throw new Error("failed to authenticate user");
        })
        .catch(error => {
            return {error: "Failed to authenticate user"}
        });
}
