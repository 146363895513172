import React from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { MixPanel } from '../components/MixPanel';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { saveSettings, fetchSettings } from '../actions/settings';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const styles = theme => ({
    app: {
      flexGrow: 1,
      height: 800,
      marginBottom: theme.spacing(4),
      // background: 'linear-gradient(-45deg, rgba(32,178,170,1) 0%, rgba(51,51,51,1) 30%, rgba(51,51,51,1) 50%, rgba(51,51,51,1) 80%, rgba(32,178,170,1) 100%)'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(4),
    },
    chipDiv: {
        display: 'flex',
        flex: 0.8,
        justifyContent: 'center',
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column',
        },
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    navContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    emailContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(8),
    },
    label: {
        marginRight: theme.spacing(2)
    },
    formControl: {
        minWidth: 320,
        display: 'flex',
        // flexDirection: 'row',
        // alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(3),
            marginLeft: 'auto',
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: theme.spacing(2),
        },
    },
    formControlFake: {
        marginRight: theme.spacing(3),
        minWidth: 320,
        marginLeft: 'auto',
        display: 'flex',
        // flexDirection: 'row',
        // alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
    },
    button: {
        marginTop: theme.spacing(8),
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
        },
        backgroundColor: theme.palette.primary.darker,
        color: 'white',
        "&:hover": {
            backgroundColor: theme.palette.primary.main
        },
    },
    emailInput: {
        width: 290
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            topic1: '',
            subTopic1: [],
            topic2: '',
            subTopic2: [],
            emailError: false,
            topic1Error: false,
            topic2Error: false,
            subtopic1Error: false,
            subtopic2Error: false,
            openSuccessSave: false,
        }
    }

    componentDidMount() {
        if (this.props.isLoggedIn) {
            this.props.fetchSettings(this.props.user.twitter_id).then(res=> {
                MixPanel.track('View Settings Page', {'User ID': this.props.user.twitter_id, 'Username': this.props.user.name})
                this.setState({
                    email: this.props.email,
                    topic1: Object.keys(this.props.topics)[0],
                    topic2: Object.keys(this.props.topics)[1],
                    subTopic1: Object.values(this.props.topics)[0],
                    subTopic2: Object.values(this.props.topics)[1],
                })
            })
        } else {
            this.props.history.push(`/`);
        }
    }

    handleTopicChange = (event, topicField, subtopicField) => {
        let subtopics;
        if (event.target.value === ''){
            subtopics = []
        } else {
            subtopics = this.props.initialTopics.filter(topic => topic.topic === event.target.value)[0].subtopics.map(s => s.subtopic);
        }

        this.setState({
            [topicField]: event.target.value,
            [subtopicField]: subtopics
        })
    }

    handleSubtopicChange = (event, subtopicField) => {
        this.setState({[subtopicField]: event.target.value})
    }

  handleSave = () => {
      if (!this.state.email) {
          this.setState({emailError: true})
          return;
      }
      if (!this.state.topic1) {
          this.setState({topic1Error: true})
          return;
      }
      if (this.state.subTopic1.length === 0) {
          this.setState({subtopic1Error: true})
          return;
      }
      if (!this.state.topic2) {
          this.setState({topic2Error: true})
          return;
      }
      if (this.state.subTopic2.length === 0) {
          this.setState({subtopic2Error: true})
          return;
      }
      const topic1 = this.props.initialTopics.filter(item => item.topic === this.state.topic1)
      const topic2 = this.props.initialTopics.filter(item => item.topic === this.state.topic2)
      const subtopics1 = topic1[0].subtopics.filter(topic => this.state.subTopic1.includes(topic.subtopic)).map(t => t._id);
      const subtopics2 = topic2[0].subtopics.filter(topic => this.state.subTopic2.includes(topic.subtopic)).map(t => t._id);
      const mergedSubtopics = [...subtopics1, ...subtopics2]
      this.props.saveSettings(this.props.user.twitter_id, mergedSubtopics, this.state.email).then(res => {
          this.setState({openSuccessSave: true})
          setTimeout(function () { this.props.history.push('/'); }.bind(this), 3000);
      })
  }

  handleChange = (event) => {
      const { value, name } = event.target;
      this.setState({
        [name]: value
      });
  }

  render() {
      const { classes } = this.props;
      return (
              <div className={classes.app}>
                  <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={this.state.openSuccessSave}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ openSuccessSave: !this.state.openSuccessSave })}
                  >
                      <Alert onClose={() => this.setState({ openSuccessSave: !this.state.openSuccessSave })} severity="success">
                          <Typography align='left' variant="subtitle1">
                            Your email digest settings were saved. <span role="img" aria-label="emoji ok">👌</span> <br/>
                          </Typography>
                      </Alert>
                  </Snackbar>
                  <Container maxWidth="md" className={classes.container}>
                      <Typography variant='h4' align='left' className={classes.title}>
                          Settings: Email digest of threads
                      </Typography>
                      <Container maxWidth="md" className={classes.emailContainer}>
                          <FormControl className={classes.formControl}>
                              <TextField
                                label="Email"
                                autoComplete='off'
                                name="email"
                                error={this.state.emailError}
                                value={this.state.email}
                                onChange={this.handleChange}
                                className={classes.emailInput}
                                helperText={this.state.emailError ? "Input an email address" : "Email address receiving the email digest"}
                              />
                           </FormControl>
                           <FormControl className={classes.formControlFake}>
                              <TextField
                                label="Email"
                                autoComplete='off'
                                style={{'visibility': 'hidden'}}
                              />
                          </FormControl>
                      </Container>
                      <Typography variant='h5' align='left'>
                          Pick your topics
                      </Typography>
                      <Container maxWidth='md' className={classes.navContainer}>
                         <div className={classes.chipDiv}>
                              <FormControl className={classes.formControl} error={this.state.topic1Error}>
                                  <InputLabel id="topic1-label" className={classes.label}>Topic</InputLabel>
                                  <Select
                                    labelId="topic1-label"
                                    id="topic1-select"
                                    value={this.state.topic1}
                                    onChange={(event) => this.handleTopicChange(event, 'topic1', 'subTopic1')}
                                    style={{width: 300, marginRight: 20}}
                                  >
                                      {this.props.initialTopics.filter(t => t.active && t.topic !== this.state.topic2).map(topic => (
                                          <MenuItem key={topic._id} value={topic.topic}>{topic.topic}</MenuItem>
                                      ))}
                                  </Select>
                                  {this.state.topic1Error && <FormHelperText>Select 1 topic</FormHelperText>}
                              </FormControl>
                              <FormControl className={classes.formControl} error={this.state.subtopic1Error}>
                                  <InputLabel id="subtopic1-label" className={classes.label}>Subtopic</InputLabel>
                                  <Select
                                    labelId="subtopic1-label"
                                    multiple
                                    id="subtopic1-select"
                                    value={this.state.subTopic1}
                                    onChange={(event) => this.handleSubtopicChange(event, 'subTopic1')}
                                    MenuProps={MenuProps}
                                    renderValue={(selected) => selected.join(', ')}
                                    style={{width: 300}}
                                  >
                                      {this.state.topic1 &&
                                          this.props.initialTopics.map(t => t.topic).includes(this.state.topic1) &&
                                          this.props.initialTopics.filter(item => item.topic === this.state.topic1)[0].subtopics.map(topic => (
                                              <MenuItem key={topic._id} value={topic.subtopic}>
                                                  <Checkbox checked={this.state.subTopic1.indexOf(topic.subtopic) > -1} color="primary"/>
                                                  <ListItemText primary={topic.subtopic} />
                                              </MenuItem>
                                      ))}
                                  </Select>
                                  {this.state.subtopic1Error && <FormHelperText>At least 1 subtopic is needed</FormHelperText>}
                              </FormControl>
                          </div>
                      </Container>
                      <Container maxWidth='md' className={classes.navContainer}>
                         <div className={classes.chipDiv}>
                              <FormControl className={classes.formControl} error={this.state.topic2Error}>
                                  <InputLabel id="topic2-label" className={classes.label}>Topic</InputLabel>
                                  <Select
                                    labelId="topic2-label"
                                    id="topic2-select"
                                    value={this.state.topic2}
                                    onChange={(event) => this.handleTopicChange(event, 'topic2', 'subTopic2')}
                                    style={{width: 300, marginRight: 20}}
                                  >
                                      {this.props.initialTopics.filter(t => t.active && t.topic !== this.state.topic1).map(topic => (
                                          <MenuItem key={topic._id} value={topic.topic}>{topic.topic}</MenuItem>
                                      ))}
                                  </Select>
                                  {this.state.topic2Error && <FormHelperText>Select 1 topic</FormHelperText>}
                              </FormControl>
                              <FormControl className={classes.formControl} error={this.state.subtopic2Error}>
                                  <InputLabel id="subtopic2-label" className={classes.label}>Subtopic</InputLabel>
                                  <Select
                                    labelId="subtopic2-label"
                                    multiple
                                    id="subtopic2-select"
                                    value={this.state.subTopic2}
                                    onChange={(event) => this.handleSubtopicChange(event, 'subTopic2')}
                                    MenuProps={MenuProps}
                                    renderValue={(selected) => selected.join(', ')}
                                    style={{width: 300}}
                                  >
                                      {this.state.topic2 &&
                                          this.props.initialTopics.map(t => t.topic).includes(this.state.topic2) &&
                                          this.props.initialTopics.filter(item => item.topic === this.state.topic2)[0].subtopics.map(topic => (

                                          <MenuItem key={topic._id} value={topic.subtopic}>
                                              <Checkbox checked={this.state.subTopic2.indexOf(topic.subtopic) > -1} color="primary"/>
                                              <ListItemText primary={topic.subtopic} />
                                          </MenuItem>

                                      ))}
                                  </Select>
                                  {this.state.subtopic2Error && <FormHelperText>At least 1 subtopic is needed</FormHelperText>}
                              </FormControl>
                          </div>
                      </Container>
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classes.button}
                        onClick={(e) => this.handleSave(e)}
                      >
                        Save Settings
                      </Button>
                  </Container>
              </div>
      );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveSettings: async (userId, topics, email) => dispatch(saveSettings(userId, topics, email)),
    fetchSettings: async (userId) => dispatch(fetchSettings(userId)),
  };
}

function mapStateToProps(state, props) {
  return {
    initialTopics: state.threads.initialTopics ? state.threads.initialTopics : [],
    user: state.auth.user ? state.auth.user : {twitter_id: ''},
    email: state.settings.email? state.settings.email : state.auth.user && state.auth.user.email ? state.auth.user.email: '',
    topics: state.settings.topics ? state.settings.topics : {},
    isLoggedIn: state.auth.isLoggedIn,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Settings)));
