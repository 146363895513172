import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers'
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import localforage from 'localforage';

const persistConfig = {
    key: 'root',
    storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export let store = createStore(persistedReducer, applyMiddleware(thunk))
export let persistor = persistStore(store)
