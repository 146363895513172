import {
    CLEAR_SESSION,
    SET_SESSION_STARTED,
    SET_SESSION_FAILED,
    SET_SESSION_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  isLoggedIn: false,
  isFetching: false,
  error: null,
  user: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION_STARTED:
      return {
        ...state,
        isLoggedIn: false,
        isFetching: true,
        error: null,
    }
    case SET_SESSION_FAILED:
      return {
           ...state,
          error: action.payload.error,
          isLoggedIn: false,
          isFetching: false,
    }
    case SET_SESSION_SUCCESS:
      return {
         ...state,
        user: action.payload.user,
        isLoggedIn: true,
        isFetching: false,
        error: null
    }
    case CLEAR_SESSION:
      return initialState

    default:
      return state
  }
}

export default authReducer
