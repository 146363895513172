import {
    FETCH_THREADS_STARTED,
    FETCH_THREADS_FAILED,
    FETCH_THREADS_SUCCESS,
    FETCH_CURRENT_THREAD_STARTED,
    FETCH_CURRENT_THREAD_FAILED,
    FETCH_CURRENT_THREAD_SUCCESS,
    NO_REFRESH,
    REFRESH,
    SET_FILTERS,
    FETCH_THREAD_USERS_STARTED,
    FETCH_THREAD_USERS_SUCCESS,
    FETCH_THREAD_USERS_FAILED,
    FETCH_THREAD_TOPICS_STARTED,
    FETCH_THREAD_TOPICS_SUCCESS,
    FETCH_THREAD_TOPICS_FAILED,
    UPDATE_THREAD_TOPIC_STARTED,
    UPDATE_THREAD_TOPIC_SUCCESS,
    UPDATE_THREAD_TOPIC_FAILED,
} from '../constants/actionTypes';

const initialState = {
  error: null,
  data: {threads: []},
  current: {},
  initialUsers: [],
  refresh: true,
  initialTopics: [],
  topic: [],
}

const threadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_THREADS_STARTED:
      return {
        ...state,
        error: null,
        loading: true
    }
    case FETCH_THREADS_FAILED:
      return {
           ...state,
          error: action.payload.error,
          data: {threads: []},
          loading: false
    }
    case FETCH_THREADS_SUCCESS:
      return {
         ...state,
        data: action.payload.data,
        error: null,
        loading: false
    }
    case FETCH_CURRENT_THREAD_STARTED:
      return {
        ...state,
        error: null,
    }
    case FETCH_CURRENT_THREAD_FAILED:
      return {
           ...state,
          error: action.payload.error,
          current: {}
    }
    case FETCH_CURRENT_THREAD_SUCCESS:
      return {
         ...state,
        current: action.payload.data,
        error: null
    }
    case FETCH_THREAD_USERS_STARTED:
      return {
        ...state,
        error: null,
    }
    case FETCH_THREAD_USERS_FAILED:
      return {
           ...state,
          error: action.payload.error,
          initialUsers: []
    }
    case FETCH_THREAD_USERS_SUCCESS:
      return {
         ...state,
        initialUsers: action.payload.data,
        error: null
    }
    case FETCH_THREAD_TOPICS_STARTED:
      return {
        ...state,
        error: null,
    }
    case FETCH_THREAD_TOPICS_FAILED:
      return {
           ...state,
          error: action.payload.error,
          initialTopics: []
    }
    case FETCH_THREAD_TOPICS_SUCCESS:
      return {
         ...state,
        initialTopics: action.payload.data.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1),
        error: null
    }
    case UPDATE_THREAD_TOPIC_STARTED:
      return {
          ...state,
          error: null
      }
    case UPDATE_THREAD_TOPIC_SUCCESS:
      return {
          ...state,
          error: null,
      }
    case UPDATE_THREAD_TOPIC_FAILED:
      return {
        ...state,
        error: action.payload.error,
      }
    case NO_REFRESH:
        return {
           ...state,
          refresh: false
      }
    case REFRESH:
        return {
        ...state,
        refresh: true
    }
    case SET_FILTERS:
        return {
        ...state,
        users: action.payload.users,
        topic: action.payload.topic,
        page: action.payload.page,
        search: action.payload.search,
        sort: action.payload.sort,
    }
    default:
      return state
  }
}

export default threadsReducer
