import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import "typeface-rochester";

// A custom theme for this app
const baseTheme = createTheme();
export const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#03a9f4',
      lighter: '#35baf6',
      darker: "#0276aa",
      other: "#0276aa",
      highlight: '#35baf6',
    },
    error: {
      main: red.A400,
    },
    bar: {
      main: '#fff',
    },
    background: {
        default: '#fff',
        other: '#ECEFF1',
        paper: '#fff',
    },
    paper: {
        default: '#fff',
        other: '#fafafa'
    },
    line: {
        default: 'rgba(0,0,0,0.5)',
        lighter: 'rgba(0,0,0,0.2)'
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    allVariants: {
      color: "rgba(0, 0, 0, 0.87)"
    },
    body2: {
        fontFamily: ['charter', 'Georgia', 'Cambria', "Times New Roman", 'Times', 'serif'],
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: 1.5,
        letterSpacing: "0.00938em"
    },
    h3: {
        fontFamily: ['Rochester', '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',]
    }
  },
  overrides: {
    MuiCard: {
      root: {
        "&.MuiSubscribeCard--01": {
          transition: "0.3s",
          backgroundColor: '#ECEFF1',
          marginTop: baseTheme.spacing(4),
          padding: baseTheme.spacing(4),
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          margin: "auto",
          boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
          "&:hover": {
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
          },
          "& .MuiCardMedia-root": {
            paddingTop: "56.25%"
          },
          "& .MuiCardContent-root": {
            textAlign: "left",
            padding: baseTheme.spacing(3)
          },
          "& .MuiDivider-root": {
            margin: `${baseTheme.spacing(3)}px 0`
          },
          "& .MuiTypography--heading": {
            fontWeight: "bold"
          },
          "& .MuiTypography--subheading": {
            lineHeight: 1.8
          },
          "& .MuiAvatar-root": {
            display: "inline-block",
            border: "2px solid white",
            "&:not(:first-of-type)": {
              marginLeft: -baseTheme.spacing(1)
            }
          }
        },
        "&.MuiThreadCard--01": {
            transition: "0.3s",
            margin: "auto",
            height: "100%",
            [baseTheme.breakpoints.only('xs')]: {
                maxWidth: 300,
            },
            width: 326,
            "&:hover": { transform: "scale3d(1.05, 1.05, 1)",},
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
            "& .MuiCardMedia-root": {
              paddingTop: "56.25%"
            },
            "& .MuiCardContent-root": {
              textAlign: "left",
            },
            "& .MuiDivider-root": {
              margin: `${baseTheme.spacing(3)}px 0`
            },
            "& .MuiTypography--heading": {
              fontWeight: "bold"
            },
            "& .MuiTypography--subheading": {
              lineHeight: 1.8
            },
            "& .MuiAvatar-root": {
              display: "inline-block",
              border: "2px solid white",
              "&:not(:first-of-type)": {
                marginLeft: -baseTheme.spacing(1)
              }
            }
        },
        "&.MuiThreadPage--01": {
            transition: "0.3s",
            padding: baseTheme.spacing(2),
            height: "100%",
            [baseTheme.breakpoints.only('xs')]: {
                maxWidth: 300,
            },
            width: 820,
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
            "& .MuiCardMedia-root": {
              paddingTop: "56.25%"
            },
            "& .MuiCardContent-root": {
              textAlign: "left",
            },
            "& .MuiDivider-root": {
              margin: `${baseTheme.spacing(3)}px 0`
            },
            "& .MuiTypography--heading": {
              fontWeight: "bold"
            },
            "& .MuiTypography--subheading": {
              lineHeight: 1.8
            },
            "& .MuiAvatar-root": {
              display: "inline-block",
              border: "2px solid white",
              "&:not(:first-of-type)": {
                marginLeft: -baseTheme.spacing(1)
              }
            }
        }
      }
    }
  }
});

// A custom theme for this app
export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
        main: '#03a9f4',
        lighter: '#35baf6',
        darker: "#0276aa",
        other: '#35baf6',
        highlight: "#0276aa"
    },
    error: {
      main: red.A400,
    },
    bar: {
      // main: '#2b2b2b',
    },
    background: {
        default: '#16212C',
        paper: '#233546'
    },
    paper: {
        default: '#233546',
        other: '#233546'
    },
    line: {
        default: 'rgba(255,255,255,0.5)',
        lighter: 'rgba(255,255,255,0.2)'
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    allVariants: {
      color: "#fff"
    },
    body2: {
        fontFamily: ['charter', 'Georgia', 'Cambria', "Times New Roman", 'Times', 'serif'],
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: 1.5,
        letterSpacing: "0.00938em"
    },
    h3: {
        fontFamily: ['Rochester', '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',]
    }
  },
  overrides: {
    MuiCard: {
      root: {
        "&.MuiSubscribeCard--01": {
          transition: "0.3s",
          backgroundColor: '#2a3e53',
          marginTop: baseTheme.spacing(4),
          padding: baseTheme.spacing(4),
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          margin: "auto",
          boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
          "&:hover": {
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
          },
          "& .MuiCardMedia-root": {
            paddingTop: "56.25%"
          },
          "& .MuiCardContent-root": {
            textAlign: "left",
            padding: baseTheme.spacing(3)
          },
          "& .MuiDivider-root": {
            margin: `${baseTheme.spacing(3)}px 0`
          },
          "& .MuiTypography--heading": {
            fontWeight: "bold"
          },
          "& .MuiTypography--subheading": {
            lineHeight: 1.8
          },
          "& .MuiAvatar-root": {
            display: "inline-block",
            border: "2px solid white",
            "&:not(:first-of-type)": {
              marginLeft: -baseTheme.spacing(1)
            }
          }
        },
        "&.MuiThreadCard--01": {
            transition: "0.3s",
            margin: "auto",
            height: "100%",
            backgroundColor: "#233546",
            [baseTheme.breakpoints.only('xs')]: {
                maxWidth: 300,
            },
            width: 326,
            "&:hover": { transform: "scale3d(1.05, 1.05, 1)",},
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
            "& .MuiCardMedia-root": {
              paddingTop: "56.25%"
            },
            "& .MuiCardContent-root": {
              textAlign: "left",
            },
            "& .MuiDivider-root": {
              margin: `${baseTheme.spacing(3)}px 0`
            },
            "& .MuiTypography--heading": {
              fontWeight: "bold"
            },
            "& .MuiTypography--subheading": {
              lineHeight: 1.8
            },
            "& .MuiAvatar-root": {
              display: "inline-block",
              border: "2px solid white",
              "&:not(:first-of-type)": {
                marginLeft: -baseTheme.spacing(1)
              }
            }
        },
        "&.MuiThreadPage--01": {
            transition: "0.3s",
            padding: baseTheme.spacing(2),
            height: "100%",
            [baseTheme.breakpoints.only('xs')]: {
                maxWidth: 300,
            },
            width: 820,
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
            "& .MuiCardMedia-root": {
              paddingTop: "56.25%"
            },
            "& .MuiCardContent-root": {
              textAlign: "left",
            },
            "& .MuiDivider-root": {
              margin: `${baseTheme.spacing(3)}px 0`
            },
            "& .MuiTypography--heading": {
              fontWeight: "bold"
            },
            "& .MuiTypography--subheading": {
              lineHeight: 1.8
            },
            "& .MuiAvatar-root": {
              display: "inline-block",
              border: "2px solid white",
              "&:not(:first-of-type)": {
                marginLeft: -baseTheme.spacing(1)
              }
            }
        }
      }
    }
  }
});

// theme.typography.h2 = {
//   fontSize: '1.2rem',
//   [theme.breakpoints.down('xs')]: {
//     fontSize: '1.2rem',
//   },
//   [theme.breakpoints.up('xs')]: {
//     fontSize: '2.2rem',
//   },
//   [theme.breakpoints.up('sm')]: {
//     fontSize: '3.4rem',
//   },
//   [theme.breakpoints.up('md')]: {
//     fontSize: '4.6rem',
//   },
// };
//
// theme.typography.h3 = {
//   fontSize: '1.4rem',
//   [theme.breakpoints.up('sm')]: {
//     fontSize: '1.8rem',
//   },
//   [theme.breakpoints.up('md')]: {
//     fontSize: '2.4rem',
//   },
// };
